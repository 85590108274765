import axios from "axios";
import { React, useRef } from "react";
import "./Dashboard.scss"
import DashboardCard from "../../components/DashboardCard/DashboardCard";

const Dashboard = ({userCookie, selectedTab, setSelectedTab, setSelectedSub}) => {

    const availableModules = [
        {label: "Create Order", path:"create-order", tabName:"Create Order"},
        {label: "Manage Orders", path:"manage-orders", tabName:"Manage Orders"},
    ]
    const availableModulesRow2 = [
        {label: "Manage Inventory", path:"manage-inventory", tabName:"Manage Inventory"},
    ]
    // console.log("UserCookie", userCookie)
    return (
        <div className="dashboard-container">
            {/* <div className="dashboard-container__Header">
                <h1>Dashboard</h1>
            </div> */}
            <div className="dashboard-container__cardComponent">
           {availableModules.map((modules,i)=>{
            return <DashboardCard key={`${"availableModules"}${modules.label}${i}`} modulelabel={modules.label} navigateTo={modules.path} setSelected={setSelectedTab} subContent={setSelectedSub} tabName={modules.tabName}/>
           })} 
           </div>
           <div className="dashboard-container__cardComponent">
           {availableModulesRow2.map((modules,i)=>{
            return <DashboardCard key={`${"availableModules"}${modules.label}${i}`} modulelabel={modules.label} navigateTo={modules.path} setSelected={setSelectedTab} subContent={setSelectedSub} tabName={modules.tabName}/>
           })} 
           </div>
        </div>
    )
}


export default Dashboard;