import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components';
import ProductItemCard from '../ProductItemCard/ProductItemCard'
import "./DashboardCard.scss"
import { backEndIP } from '../configuration'
import { useNavigate } from 'react-router-dom';


const DashboardCard = ({ modulelabel, userCookie, navigateTo, setSelected, subContent, tabName, setCustomerDetailsPopup, setSelfOrderDetailsPopup }) => {
    const navigate = useNavigate()

    const handelOpenTab = (modulelabel) => {
        console.log("modulelabel", modulelabel)
        if (modulelabel === "Self order") {
            setSelfOrderDetailsPopup(true)
        } else if (modulelabel === "Order for customer") {
            setCustomerDetailsPopup(true)
        }
    }

    return (
        <div className="dashboardcard-container" >
            <div className='dashboardcard-container__title' onClick={() => {
                if (subContent === undefined) {
                    setSelected(tabName);
                } else {
                    setSelected(tabName);
                    subContent(tabName)
                }
                handelOpenTab(modulelabel)
                navigate(`/${navigateTo}`)
            }}>
                {modulelabel}
            </div>
        </div>
    )
}

export default DashboardCard