import React, { createContext, useState } from 'react';

// Create the context
export const DrawerContext = createContext();

// Create the provider component
export const DrawerProvider = ({ children }) => {
    const [openDrawer, setOpenDrawer] = useState(false);
    const [placement, setPlacement] = useState('right');
    const [hubLocation, setHubLocation] = useState({ location_id: "", location_name: "" })
    const [openDeliveryTypeModal, setOpenDeliveryTypeModal] = useState(false);
  return (
    <DrawerContext.Provider value={{ openDrawer, setOpenDrawer, placement, 
    setPlacement, hubLocation, setHubLocation, openDeliveryTypeModal, setOpenDeliveryTypeModal }}>
      {children}
    </DrawerContext.Provider>
  );
};
