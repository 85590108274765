import React, { useState, useContext, createContext } from "react";

export const ListItemsContext = createContext();
export const ListItemsProvider = ({ children }) => {
  const [listItems, setListItems] = useState([]);
  return (
    <ListItemsContext.Provider value={{ listItems, setListItems }}>
      {children}
    </ListItemsContext.Provider>
  );
};
