import React, { useEffect, useState } from 'react'
import axios from 'axios'
import "./HeaderBar.scss"
import { backEndIP } from '../../configuration'
import { useLocation, useNavigate } from 'react-router-dom'

const Image = (props) => {
    return (
        <div className={`${props.classes ? props.classes : ""}`}>
            {!props.hoverState ? <>
                {props.cartItem > 0 ? <div className='absolute ml-2 top-7 border border-white p-1 w-3.5 h-3.5 bg-orange-500 flex flex-row items-center justify-center text-xs font-medium rounded-full text-te-text-white'>
                    {props.cartItem}
                </div> : null}
                <svg className='cursor-pointer' width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.07696 1L1 3.66649V12.9992C1 13.3528 1.14588 13.6919 1.40555 13.9419C1.66522 14.192 2.01741 14.3324 2.38464 14.3324H12.0771C12.4444 14.3324 12.7965 14.192 13.0562 13.9419C13.3159 13.6919 13.4618 13.3528 13.4618 12.9992V3.66649L11.3848 1H3.07696Z" stroke="#FD6125" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M1 3.66602H13.4618" stroke="#FD6125" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M9.9995 6.33203C9.9995 7.03923 9.70774 7.71746 9.1884 8.21752C8.66906 8.71758 7.96468 8.99852 7.23022 8.99852C6.49576 8.99852 5.79138 8.71758 5.27204 8.21752C4.7527 7.71746 4.46094 7.03923 4.46094 6.33203" stroke="#FD6125" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </>
                :
                <>
                    {props.cartItem > 0 ? <div className='absolute ml-2 top-7 border border-orange-500 w-3.5 h-3.5 bg-white flex flex-row items-center justify-center text-xs font-medium rounded-full text-te-text-orange'>
                        {props.cartItem}
                    </div> : null}
                    <svg className='cursor-pointer' width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.07696 1L1 3.66649V12.9992C1 13.3528 1.14588 13.6919 1.40555 13.9419C1.66522 14.192 2.01741 14.3324 2.38464 14.3324H12.0771C12.4444 14.3324 12.7965 14.192 13.0562 13.9419C13.3159 13.6919 13.4618 13.3528 13.4618 12.9992V3.66649L11.3848 1H3.07696Z" stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M1 3.66602H13.4618" stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M9.9995 6.33203C9.9995 7.03923 9.70774 7.71746 9.1884 8.21752C8.66906 8.71758 7.96468 8.99852 7.23022 8.99852C6.49576 8.99852 5.79138 8.71758 5.27204 8.21752C4.7527 7.71746 4.46094 7.03923 4.46094 6.33203" stroke="#FFFFFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </>
            }
        </div>
    )
}
const HeaderBar = ({ userName, BagList, profilePicture, cartItems, classes, currentOrderNo, setSelectedSubContent, setUserIconClicked, themeGlobal, setOpenDrawer, selectedTab,
    setSelectedTab }) => {
    const [hoverColor, setHoverColor] = useState(false)
    const [showNotifications, setShowNotifications] = useState(false)
    const [allNotifications, setAllNotifications] = useState([])
    const navigate = useNavigate()
    const [backButtonColor, setBackButtonColor] = useState(false)


    const navlinks = {
        "/all-orders": "My Orders",
        "/manage-order": "Manage Orders",
        "/all-categories": "Home",
        "/baglist": "BagList",
        // `/order/${currentOrderNo}`:"SpecficOrder",
        "/manage-inventory": "Manage Inventory",
        "/myProfile": "My Profile",
        "/help": "Help",
        "/userDashboard": "Dashboard",
        "/help": "Help",
        '/shopify': "Shopify",
        "/dashboard": "Dashboard",
        "/create-order": "Create Order"
    }
    const handleBack = async () => {
        if (window.history.length > 1) {
            // Navigate back
            navigate(-1);
        }
        console.log("window.history=>", window.frames.location.pathname)
        // const tabName = navlinks[window.parent.location.pathname]
        //   console.log("tabName", tabName)
        //}
    }
    return (
        <div className={`headerBar-container ${classes ? classes : ""}`}>
            <div className='headerBar-container__navsection'>
                <div className='headerBar-container__navsection__logoContainer'>
                    {/* <img
                        className="headerBar-container__navsection__logoContainer__phc-logo"
                        // style={{
                        //     height: "calc(150px * var(--heightmultiplier))",
                        //     width: "calc(150px * var(--heightmultiplier))",
                        //     border: "1px solid black"
                        // }}
                        src={backEndIP + "/PHC-logo.svg"}
                        alt="phc logo"
                    /> */}
                </div>
                <div className="headerBar-container__navsection__backButton" >
                    <div className="headerBar-container__navsection__backButton__container" onClick={handleBack} onMouseMove={() => { setBackButtonColor(true) }} onMouseOut={() => { setBackButtonColor(false) }}>
                        {backButtonColor ? <svg className="headerBar-container__navsection__backButton__container__svgimag" width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11 13L18 7L11 1" stroke="#FD6125" strokeWidth="1.5" strokeLinecap="round" />
                            {/* <path d="M1 6.25C0.585787 6.25 0.25 6.58578 0.25 7C0.25 7.41421 0.585787 7.75 1 7.75L1 6.25ZM18 6.25L1 6.25L1 7.75L18 7.75L18 6.25Z" /> */}
                        </svg> : <svg className="headerBar-container__navsection__backButton__container__svgimag" width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11 13L18 7L11 1" stroke="#878B8F" strokeWidth="1.5" strokeLinecap="round" />
                            {/* <path d="M1 6.25C0.585787 6.25 0.25 6.58578 0.25 7C0.25 7.41421 0.585787 7.75 1 7.75L1 6.25ZM18 6.25L1 6.25L1 7.75L18 7.75L18 6.25Z" /> */}
                        </svg>}
                        <div className="headerBar-container__navsection__backButton__container__textBack">
                            Back
                        </div>
                    </div>
                </div>
            </div>
            <div className='headerBar-container__userSection'>
               {BagList.length ? <div className='headerBar-container__userSection__bagIconContainer'>
                    <div className='headerBar-container__userSection__bagIconContainer__user'
                        onClick={() => {
                            setSelectedSubContent("BagList");
                            // setOpenDrawer(true)
                        }}
                        onMouseMove={() => { setHoverColor(true) }}
                        onMouseOut={() => { setHoverColor(false) }}
                    >
                        <div className="headerBar-container__userSection__bagIconContainer__user__bagStr">
                            Bag
                        </div>
                        <div className="headerBar-container__userSection__bagIconContainer__user__bagImg">
                            {hoverColor ?
                                <img
                                    className="headerBar-container__userSection__bagIconContainer__user__bagImg__img"
                                    src={backEndIP + "/cartBagWhite.svg"}
                                    alt="bagIcon"
                                ></img> : <img
                                    className="headerBar-container__userSection__bagIconContainer__user__bagImg__img"
                                    src={backEndIP + "/cartBag.svg"}
                                    alt="bagIcon"
                                ></img>}

                            {BagList.length === 0 ? null : <>
                            {hoverColor ?<div className="headerBar-container__userSection__bagIconContainer__user__bagImg__productCountWhite">{BagList.length}</div> :<div className="headerBar-container__userSection__bagIconContainer__user__bagImg__productCount">{BagList.length}</div>}
                            </>}
                        </div> </div>
                </div> : null}
                <div className='headerBar-container__userSection__bagIconContainer__userIcon'>
                    <div
                        className="headerBar-container__userSection__bagIconContainer__userIcon__proImg"
                        onClick={() => {
                            setUserIconClicked(true);
                        }}
                    >
                        {userName ? userName[0].toUpperCase() : ""}
                    </div>
                    {/* <svg onClick={() => {
                    setUserIconClicked(true);
                }}
                    className="headerBar-container__userSection__bagIconContaineruserIcon__img"
                    width="12" height="6" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M7.00016 8C6.64177 8 6.28338 7.86934 6.01039 7.60935L0.410539 2.27624C-0.136846 1.75493 -0.136846 0.912295 0.410539 0.390984C0.957924 -0.130328 1.8427 -0.130328 2.39008 0.390984L7.01696 4.79747L11.627 0.557643C12.1856 0.0469981 13.069 0.0616641 13.6066 0.590975C14.1442 1.12029 14.1288 1.96558 13.573 2.47623L7.97313 7.62535C7.70014 7.87601 7.35015 8 7.00016 8Z"
                        fill={themeGlobal.themeColor["--primaryColor"] === "white" ? "black" : "white"} />
                </svg> */}
                </div>
            </div>
            {/* <NotificationsPanel showNotifications={showNotifications} /> */}
        </div>
    )
}

export default HeaderBar