import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { backEndIP } from '../configuration';
import './ImportButton.scss';

const ImportCategory = ({ userCookie, handleNotifyAlert }) => {
    const [loading, setLoading] = useState(false);
    
    const handleFileSelectAndUpload = async (e) => {
        const file = e.target.files[0];
        if (!file) {
            handleNotifyAlert('notify', 'Please select a CSV file.', 'failed', () => {});
            return;
        }
        const reader = new FileReader();
        reader.onload = async (event) => {
            try {
                const csvContent = event.target.result;
                const allCategory = csvContent.split('\n').slice(1)

                for (const item of allCategory) {
                    if(item.trim()!=='')
                    await uploadToBackend(item);
                }

                e.target.value = '';
            } catch (err) {
                console.error('Error processing file:', err);
                handleNotifyAlert(
                    'notify',
                    'Error processing the file. Please ensure it is formatted correctly.',
                    'failed',
                    () => {}
                );
            }
        };

        reader.readAsText(file);
    };

    const uploadToBackend = async (item) => {
        try {
            setLoading(true);
            axios({
                url: backEndIP + `/category/add_category`,
                method: "POST",
                headers: {
                  authorization: userCookie.token,
                },
                data: {
                  name: item,
                },
                
              });
            
            handleNotifyAlert(
                'notify',
                `Category Added successfully`,
                'success',
                () => {}
            );
        } catch (err) {
            handleNotifyAlert(
                'notify',
                `Failed to add Category "${item}": ${err.response?.data?.message || 'Unknown error'}`,
                'failed',
                () => {}
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <label htmlFor="file-upload" style={{ display: 'none' }}>
                Upload CSV
            </label>
            <input
                id="file-upload"
                type="file"
                accept=".csv"
                style={{ display: 'none' }}
                onChange={handleFileSelectAndUpload}
            />
            <button
                className="import-button"
                onClick={() => document.getElementById('file-upload').click()}
                disabled={loading}
            >
                {loading ? 'Uploading...' : 'Import CSV'}
            </button>
        </div>
    );
};

export default ImportCategory;
