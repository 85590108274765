import React, { useRef } from "react";
import "./UserDropDown.scss";

function UserDropDown({ userPermission, setOpenModal,setContentOption }) {
  const modalRef = useRef();

  const closeModal = e => {
    if (modalRef.current === e.target) {
      setOpenModal(false);
    }
  };


  return (
    <div onClick={closeModal} ref={modalRef} className="UserDropDown_modalBackground" style={{ background: "none",boxShadow:"none" }}>
        <div className='userDropDown'>
          {/* <div className='userDropDown__options'
          onClick={()=>{;setOpenModal(false);setContentOption("Orders")}}>
            Orders</div> */}
          {/* <div className='userDropDown__options'
          onClick={()=>{;setOpenModal(false);setContentOption("Settings")}}>
            Settings</div> */}
            {/* {userPermission === "Granted" ?<div className='userDropDown__options'
          onClick={()=>{setOpenModal(false);setContentOption("Manage Inventory")}}>
            Manage Inventory</div> : null} */}
            <div className='userDropDown__options'
          onClick={()=>{setOpenModal(false);setContentOption("My Profile")}}>
            My Profile</div>
            <div className='userDropDown__options'
          onClick={()=>{setOpenModal(false);setContentOption("Help")}}>
            Help</div>
          <div className='userDropDown__options'
          onClick={()=>{setOpenModal(false);setContentOption("Logout")}}>
            Logout</div>
      </div>
    </div>

  );
}

export default UserDropDown;
