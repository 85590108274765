import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import BasicDateTimePicker from "../DateTimePicker/DateTimePicker";
import ListDownItem from "../ListDownItem/ListDownItem";
import "./BagListSubContent.scss";
import { backEndIP } from '../configuration'
import dayjs from "dayjs";
import { MenuItem, TextField } from "@mui/material";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import SideDrawer from "../SidebarDrawer/SideDrawer";
import { DrawerContext } from "../../context/DrawerContext";
import LocationTypeModal from "../DeliveryTypeModal/DeliveryTypeModal";
import { useNavigate } from "react-router-dom";
import { CustomerDetailContext } from "../../context/CustomerDetailContext";
import { DatePicker } from "rsuite";

const BagListSubContent = ({ SetLoadingVisibility,
  userCookie,
  userPermission, userHubLocation,
  myLocation,
  handleNotifyAlert,
  BagList,
  setBagList, allLocations,
  setCartQuantityUpdated,
  cartQuantityUpdated, handleDeleteCartItem, handleUpdateItemQuantityInBag, totalPrice,
  totalWeight,
  setTotalPrice,
  setTotalWeight, calculateTotalPrice, calculateTotalWeight, setSelectedSubContent,
  setContentOption,
  setOrderNo, setSelectedTab }) => {
  // console.log("handleUpdateItemQuantityInBag=", handleUpdateItemQuantityInBag)
  const navigate = useNavigate()
  const phoneNoElementRef = useRef()

  // const [deliveryDate, setDeliveryDate] = useState(dayjs(new Date()).add(1, "day").hour(18).minute(0))
  // const [destLocation, setDestLocation] = useState("Select");
  const [avaiblableDestLocations, setAvaiblableDestLocations] = useState({});
  // const [deliveryType, setDeliveryType] = useState("");
  // const [orderType, setOrderType] = useState("Self")
  const [selectDateVisibility, setSelectDateVisibility] = useState(false);
  const { payloadDetails, setPayloadDetails, deliveryDate, setDeliveryDate, deliveryType, source_location, setDeliveryType, orderType, setOrderType, customerData, setCustomerData, destLocation, setDestLocation, recievedOn, setRecievedOn, destination_location } = useContext(CustomerDetailContext)
  // const [customerData, setCustomerData] = useState({
  //   name: "",
  //   email: "",
  //   phoneNo: "",
  // })
  const [emailError, setEmailError] = useState(false)
  const [PhoneNoError, setPhoneNoError] = useState(false)
  const { hubLocation, setHubLocation } = useContext(DrawerContext);
  const maxWeight = 2500
  const emailFormate = /^\w+([\.-_]?\w+)*@\w*(\.\w{2,3})+$/

  useEffect(() => {
    // console.log(deliveryDate, destLocation)
    const tempMyLoc = {}
    allLocations.forEach((loc, i) => {
      if (loc._id === myLocation) {
        tempMyLoc[myLocation] = loc.location_name;
        tempMyLoc[loc.location_name] = loc._id;
      }
      if (allLocations[i]._id === userHubLocation) {
        setHubLocation({ location_id: userHubLocation, location_name: `${allLocations[i].location_name} (${allLocations[i].tag_name})` })
      }
    })
    setAvaiblableDestLocations(tempMyLoc)

  }, [allLocations])

  useEffect(()=>{
    if(BagList.length <=0){
      if(userCookie.permission === "Granted"){
        navigate("/create-order")
        setSelectedTab("Create Order")
        setSelectedSubContent("Create Order")
      } else {
          navigate("/home")
          setSelectedTab("Home")
          setSelectedSubContent("Home")
      }
    }
  })


  const TextFieldCSS = {
    padding: "calc(10px * (var(--heightmultiplier) + var(--heightmultiplier)) / 2) ",
    ".MuiInputBase-input": {
      padding: "calc(10px * (var(--heightmultiplier) + var(--heightmultiplier)) / 2) ",
      fontSize: "calc(20px * var(--heightmultiplier))",
      fontFamily: "General Sans",
      color: "var(--primaryTextColor)"
    },
    "MuiFormLabel-root": {
      color: "var(--primaryTextColor)",

    },
    ".MuiInputLabel-outlined": {
      fontSize: "calc(20px * var(--heightmultiplier))",
      fontFamily: "General Sans",
      color: "var(--primaryTextColor)",

    },
    ".MuiOutlinedInput-notchedOutline": {
      // padding: "10px",
      fontSize: "calc(20px * var(--heightmultiplier))",
      fontFamily: "General Sans",
      borderColor: "#FD6125",

    },
    ".MuiSvgIcon-root": {
      // padding: "10px",
      // fontSize: "calc(20px * var(--heightmultiplier))",
      // fontFamily:"General Sans",
      fill: "#FD6125",
      width: "calc(25px * (var(--heightmultiplier) + var(--heightmultiplier)) / 2) ",
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'var(--primaryTextColor)',
      },
      '&:hover fieldset': {
        borderColor: '#FD6125',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#FD6125',
      },
    },

    width: "calc(300px * var(--widthmultiplier))",
    // border:"1px soild black",
    // display:"flex",
    // alignItems: "center",
    // flexDirection:"row"


  }


  console.log("totalWeight", totalWeight)
  const extraWeight = maxWeight - totalWeight
  // console.log(extraWeight)


  useEffect(() => {
    setTotalPrice(calculateTotalPrice());
    setTotalWeight(calculateTotalWeight());

  }, [BagList])

  // console.log("destLocation", destLocation)



  const PlaceUserOrder = () => {
    console.log({
      orderType: orderType,
      cart: BagList,
      destination_location: orderType === "Self" ? destination_location : destLocation,
      source_location: userHubLocation,
      delivery_date: deliveryDate,
      delivery_type: deliveryType,
      customerData: { ...customerData }
    })
    SetLoadingVisibility(true);

    axios({
      url: backEndIP + "/order/placed-order",
      method: "POST",
      headers: {
        authorization: userCookie.token,
      },
      data: {
        orderType: orderType,
        cart: BagList,
        destination_location: orderType === "Self" ? destination_location : destLocation.split(",")[0],
        source_location: orderType === "Self" ? source_location : userHubLocation,
        delivery_date: deliveryDate,
        delivery_type: deliveryType,
        customerData: { ...customerData }
      }
      // data: { id: product._id , quantity:1 }
    })
      .then((resp) => {
        SetLoadingVisibility(false);
        // console.log(resp.data);
        setBagList(resp.data.cart);
        setCartQuantityUpdated(true);
        handleNotifyAlert("notify", "Order placed succesfully", "success", () => { })
        setDestLocation("Select")
        setDeliveryType("")
        setCustomerData({ name: "", email: "", phoneNo: "" })
        setOrderType("Self")
        setSelectDateVisibility(false)
        if(userCookie.permission === "Granted"){
          navigate("/manage-orders")
          setSelectedSubContent("Manage Orders")
          setSelectedTab("Manage Orders")
      } else {
          setOrderNo(resp.data.order_no);
          setContentOption("SpecficOrder")
          // navigate(`/order/${resp.data.order_no}`)
          setSelectedSubContent("SpecficOrder")
          setSelectedTab("My Orders")
      }
      })
      .catch((err) => {
        SetLoadingVisibility(false);
        if (err.response.data.message) {
          // window.alert(err.response.data.message);
          handleNotifyAlert("notify", err.response.data.message, "failed", () => { })
        }
        else handleNotifyAlert("notify", "some error occurered", "failed", () => { });
        //console.log(err);
      });
  }
  const handlePlaceOrderClick = () => {
    //  console.log(phoneNoElementRef.current.value

    if (orderType === "Self") {
      if (userPermission === "Granted") {
        if (cartQuantityUpdated && deliveryType !== "") {
          handleNotifyAlert("alert", `Are you sure you want to place order for Destination location : ${avaiblableDestLocations[myLocation]}?`, "alert", PlaceUserOrder)
        }
        else if (deliveryType === "") {
          handleNotifyAlert("notify", `Please select "delivery type" for the order`, "alert", () => { })
        }
        else {
          SetLoadingVisibility(false);
          handleNotifyAlert("notify", "Please update Cart first", "alert", () => { })
        }
      } else {
        setDestLocation(myLocation)
        if (cartQuantityUpdated && deliveryType !== "") {
          handleNotifyAlert("alert", `Are you sure you want to place order for Destination location : ${avaiblableDestLocations[myLocation]}?`, "alert", PlaceUserOrder)
        }
        else if (deliveryType === "") {
          handleNotifyAlert("notify", `Please select "delivery type" for the order`, "alert", () => { })
        }
        else {
          SetLoadingVisibility(false);
          handleNotifyAlert("notify", "Please update Cart first", "alert", () => { })
        }
      }

    } else if (orderType === "Customer") {
      // console.log(phoneNoElementRef.current.value)
      if (customerData.name === "" && customerData.email === "" && customerData.phoneNo === "") {
        handleNotifyAlert("notify", "Please fill all the customers details", "alert", () => { })
      } else if (customerData.phoneNo.slice(3).length !== 10) {
        setPhoneNoError(true)
        handleNotifyAlert("notify", "Enter Vaild Phone no", "alert", () => { })
      } else if (customerData.email.match(emailFormate) === null) {
        setEmailError(true)
        handleNotifyAlert("notify", "Enter User's Vaild Email", "alert", () => { })
      }
      else {
        // const tempCustomerData ={ ...customerData, phoneNo: `+91${phoneNoElementRef.current.value}` }
        // setCustomerData(tempCustomerData)
        if (cartQuantityUpdated && destLocation !== "Select" && deliveryType !== "") {
          console.log("customerData", customerData)
          handleNotifyAlert("alert", `Are you sure you want to place order for Destination location : ${destLocation.split(",")[1]}?`, "alert", PlaceUserOrder)
        }
        else if (destLocation === "Select") {
          handleNotifyAlert("notify", "Please select destination Location", "alert", () => { })
        }
        else if (deliveryType === "") {
          handleNotifyAlert("notify", `Please select "delivery type" for the order`, "alert", () => { })
        }
        else {
          SetLoadingVisibility(false);
          handleNotifyAlert("notify", "Please update Cart first", "alert", () => { })
        }
      }
    }
  }

  // const handleUpdateCartClick = () => {
  //   SetLoadingVisibility(true);
  //   if (totalWeight > maxWeight) {
  //     const extra = totalWeight - maxWeight
  //     SetLoadingVisibility(false);
  //     handleNotifyAlert("alert", `cart weight is ${extra}g more than maximum weight, please remove some item from cart`, "alert", () => { })
  //   } else {
  //     axios({
  //       url: backEndIP + "/cart-add/update-cart",
  //       method: "PUT",
  //       headers: {
  //         authorization: userCookie.token,
  //       },
  //       data: {
  //         cart: BagList
  //       }
  //       // data: { id: product._id , quantity:1 }
  //     })
  //       .then((resp) => {
  //         SetLoadingVisibility(false);
  //         console.log(resp.data, cartQuantityUpdated);
  //         setBagList(resp.data.cart);
  //         setCartQuantityUpdated(true);
  //         handleNotifyAlert("notify", "Cart updated Successfully", "success", () => { })
  //       })
  //       .catch((err) => {
  //         SetLoadingVisibility(false);
  //         if (err.response.data.message)
  //           // window.alert(err.response.data.message);
  //           handleNotifyAlert("notify", err.response.data.message, "alert", () => { })
  //         else window.alert("some error occurered");
  //         //console.log(err);
  //       });
  //   }
  // }





  const handleDeliveryRadioChange = (e) => {
    setDeliveryType(e.target.value)
    // if (e.target.value === "Urgent") {
    //   setSelectDateVisibility(false);
    // }
    // else {
    //   setSelectDateVisibility(true)
    // }
    // console.log("deliverytype", deliveryType)
  }

  useEffect(() => {
    console.log("cartQuantityUpdated", cartQuantityUpdated)
  }, [cartQuantityUpdated])

  console.log("Customer", destLocation, destination_location)
  return (
    <div className="BagListSubContent-container">
      <div className="BagListSubContent-container__topBar">

        {userPermission === "Granted" ?
          <div className="BagListSubContent-container__totalItems BagListSubContent-container__totalItems_user">
            <div className="BagListSubContent-container__totalItems__bagList">
              <div className="BagListSubContent-container__title">Bag List</div>
              <div>Total Items : {BagList.length}</div>
            </div>

            {userCookie.permission === "Granted" ? <div className="BagListSubContent-container__totalItems__inputRadioCont">
              <div className="BagListSubContent-container__inputRadio">
                <div className="BagListSubContent-container__inputRadio__label">Order Type</div>
                <div style={{width: "calc(5px * var(--widthmultiplier))"}}>:</div>
                <div className="BagListSubContent-container__inputRadio__value">{orderType}</div>
                {/* <FormControl>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={orderType}
                  onChange={(e) => { setOrderType(e.target.value) }}
                  className="BagListSubContent-container__inputRadio__value"
                  readOnly
                >
                  <FormControlLabel className="BagListSubContent-container__inputRadio__value__input" value="Self" control={<Radio style={{ color: "#4285F4" }} />} label="Self" />
                  <FormControlLabel className="BagListSubContent-container__inputRadio__value__input" value="Customer" control={<Radio style={{ color: "#4285F4" }} />} label="Customer" />
                </RadioGroup>
              </FormControl> */}
              </div>
              {orderType !== "Self" && <div className="BagListSubContent-container__inputRadio">
                <div className="BagListSubContent-container__inputRadio__label">Received On</div>
                <div style={{width: "calc(5px * var(--widthmultiplier))"}}>:</div>
                <div className="BagListSubContent-container__inputRadio__value">{recievedOn}</div>
              </div>}
            </div> : null}
            {orderType !== "Self" && <div className="BagListSubContent-container__totalItems__locationCont1">
              <div className="BagListSubContent-container__totalItems__locationCont1__label"> Customer Details</div>
              <div className="BagListSubContent-container__customerInput">
                <div className="BagListSubContent-container__customerInput__label">Name</div>
                <div className="BagListSubContent-container__customerInput__value">
                  <input type="text"
                    value={customerData.name}
                    placeholder="Enter name"
                    className="BagListSubContent-container__customerInput__value__input"
                    // onChange={(e) => { setCustomerData({ ...customerData, ["name"]: e.target.value }) }}
                    readOnly
                  /></div>
              </div>
              <div className="BagListSubContent-container__customerInput" >
                <div className="BagListSubContent-container__customerInput__label">Email</div>
                <div className="BagListSubContent-container__customerInput__value" >
                  <input type="email"
                    value={customerData.email}
                    style={{ color: emailError === true ? "red" : "var(--primaryTextColor)" }}
                    placeholder="Enter Email"
                    className="BagListSubContent-container__customerInput__value__input"
                    // onChange={(e) => {
                    //   setCustomerData({ ...customerData, ["email"]: e.target.value })
                    //   setEmailError(false)
                    // }}
                    readOnly
                  /></div>
              </div>
              <div className="BagListSubContent-container__customerInput">
                <div className="BagListSubContent-container__customerInput__label">Phone</div>
                <div className="BagListSubContent-container__customerInput__value">
                  <input type="text"
                    placeholder={"+91"}
                    //placeholder="Enter Confirm Password"

                    style={{ width: '14%', borderColor: PhoneNoError === true ? "red" : "var(--primaryTextColor)" }}
                    className="BagListSubContent-container__customerInput__value__input1" readOnly />
                  <input
                    // ref={phoneNoElementRef}
                    type="string"
                    //a//utoFocus={true}
                    value={customerData.phoneNo.slice(3)}
                    style={{ borderColor: PhoneNoError === true ? "red" : "var(--primaryTextColor)" }}
                    placeholder="Enter Phone no"
                    className="BagListSubContent-container__customerInput__value__input2"
                    // onChange={(e) => {
                    //   if (e.target.value.length >= 10) {
                    //     setPhoneNoError(false)
                    //     phoneNoElementRef.current.value = e.target.value.slice(0, 10);
                    //     const phNo = e.target.value.slice(0, 10);
                    //     setCustomerData({ ...customerData, ["phoneNo"]: `+91${phNo}` })
                    //   }
                    // }}
                    readOnly
                  />
                </div>
              </div>
            </div>}
            <div className="BagListSubContent-container__totalItems__locationCont">
              <div className="BagListSubContent-container__inputFiled">
                <div className="BagListSubContent-container__inputFiled__label">Source Location</div>
                <div className="BagListSubContent-container__inputFiled__value">
                  <input
                    readOnly
                    value={hubLocation.location_name}
                    //placeholder="Enter Confirm Password"
                    className="BagListSubContent-container__inputFiled__value__input"

                  //onChange={(e) => { setUpdatePasswordData({ ...updatePasswordData, ["confirm_password"]: e.target.value }) }}
                  /></div>
              </div>
              <div className="BagListSubContent-container__inputFiled">
                <div className="BagListSubContent-container__inputFiled__label">Destination Location</div>
                <div className="BagListSubContent-container__inputFiled__value">
                  {orderType !== "Self" ?
                    <div className="BagListSubContent-container__inputFiled__value__input">
                      {destLocation.split(",")[1]}
                    </div> : <div className="BagListSubContent-container__inputFiled__value__input">
                      {typeof destination_location === "String" ? avaiblableDestLocations[destination_location.split(",")[0]] : destination_location?.name}
                    </div>}
                  {/* {orderType !== "Self" ? <select
                  value={destLocation}
                  onChange={(e) => { setDestLocation(e.target.value) }}
                  //defaultValue={destLocation}
                  //placeholder="Enter Confirm Password"
                  className="BagListSubContent-container__inputFiled__value__input"
                >
                  <option value={"Select"}>
                    Select
                  </option>
                  {allLocations.map((option) => {
                    //console.log("option", option._id, option.location_name)
                    if (option.tag_name !== "Office" && option.tag_name !== "Meghalaya")
                      return <option key={option._id} value={[option._id, option.location_name]}>
                        {option.location_name}
                      </option>
                    else {
                      return
                    }
                  })}</select> :
                  <div className="BagListSubContent-container__inputFiled__value__input">
                    {avaiblableDestLocations[myLocation]}
                  </div>} */}
                </div>
              </div>
            </div>
            <div className="BagListSubContent-container__totalItems__locationCont">
              <div className="BagListSubContent-container__deliveryType">
                <FormControl>
                  <FormLabel id="demo-controlled-radio-buttons-group"
                    sx={{
                      color: "var(--primaryTextColor)",
                      ".MuiInputBase-input": {
                        padding: "calc(10px * (var(--heightmultiplier) + var(--heightmultiplier)) / 2) ",
                        fontSize: "calc(20px * var(--heightmultiplier))",
                        fontFamily: "General Sans",
                        color: "var(--primaryTextColor)"
                      },
                      "MuiFormLabel-root": {
                        color: "var(--primaryTextColor)",

                      },
                      ".MuiFormLabel-outlined": {
                        fontSize: "calc(28px * var(--heightmultiplier))",
                        fontFamily: "General Sans",
                        color: "#FD6125",
                      },
                      ".MuiFormControl-root": {
                        fontSize: "calc(20px * var(--heightmultiplier))",
                        color: "var(--primaryTextColor)",
                      },
                    }}>Delivery Type</FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={deliveryType}
                    onChange={handleDeliveryRadioChange}
                    sx={{
                      ".MuiTypography-root": {
                        fontSize: "calc(20px * var(--heightmultiplier))"
                      },
                      ".MuiFormControl-root": {
                        fontSize: "calc(20px * var(--heightmultiplier))"
                      },
                      color: "var(--primaryTextColor)",
                      width: "calc(300px * var(--widthmultiplier))",
                      //border:"1px solid var(--primaryTextColor)",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",

                    }}
                  >
                    <FormControlLabel value="Urgent" control={<Radio sx={{ color: "var(--primaryTextColor)" }} />} label="Urgent" />
                    <FormControlLabel value="Scheduled" control={<Radio sx={{ color: "var(--primaryTextColor)" }} />} label="Scheduled" />
                  </RadioGroup>
                </FormControl>
              </div>
              <div className="BagListSubContent-container__datePicker">
                <DatePicker
                  format="MM/dd/yyyy hh:mm aa"
                  value={deliveryDate}
                  oneTap={true}
                  onChange={(date) => {
                    setDeliveryDate(date)
                    setSelectDateVisibility(false);
                  }}
                  className='BagListSubContent-container__datePicker__dateContainer'
                  // open={true}
                  placement="bottom"
                />
              </div></div>
          </div> :
          <div className="BagListSubContent-container__totalItems">
            <div className="BagListSubContent-container__totalItems__bagList2">
              <div className="BagListSubContent-container__title">Bag List</div>
              <div>Total Items : {BagList.length}</div>
            </div>
            <div className="BagListSubContent-container__totalItems__locationCont2">
              <div className="BagListSubContent-container__inputFiled2">
                <div className="BagListSubContent-container__inputFiled2__label">Source Location</div>
                <div className="BagListSubContent-container__inputFiled2__value">
                  <input
                    readOnly
                    value={hubLocation.location_name}
                    //placeholder="Enter Confirm Password"
                    className="BagListSubContent-container__inputFiled2__value__input"
                  //onChange={(e) => { setUpdatePasswordData({ ...updatePasswordData, ["confirm_password"]: e.target.value }) }}
                  /></div>
              </div>
              <div className="BagListSubContent-container__inputFiled2">
                <div className="BagListSubContent-container__inputFiled2__label">Destination Location</div>
                <div className="BagListSubContent-container__inputFiled2__value">
                  {/* {allLocations.map((option) => {
                  //console.log("option", option._id, option.location_name)
                  if (option.tag_name !== "Office" && option.tag_name !== "Meghalaya")
                    if (option._id === myLocation) {
                      // setDestLocation([option._id, option.location_name])
                      return (<div className="BagListSubContent-container__inputFiled__value__input">
                        {option.location_name}
                      </div>)
                       
                    }
                })} */}
                  <div className="BagListSubContent-container__inputFiled__value__input">
                    {avaiblableDestLocations[myLocation]}
                  </div>
                </div>
              </div>
            </div>
            <div className="BagListSubContent-container__totalItems__locationCont2">
              <div className="BagListSubContent-container__deliveryType">
                <FormControl>
                  <FormLabel id="demo-controlled-radio-buttons-group"
                    sx={{
                      color: "var(--primaryTextColor)",
                      ".MuiInputBase-input": {
                        padding: "calc(10px * (var(--heightmultiplier) + var(--heightmultiplier)) / 2) ",
                        fontSize: "calc(20px * var(--heightmultiplier))",
                        fontFamily: "General Sans",
                        color: "var(--primaryTextColor)"
                      },
                      "MuiFormLabel-root": {
                        color: "var(--primaryTextColor)",

                      },
                      ".MuiFormLabel-outlined": {
                        fontSize: "calc(28px * var(--heightmultiplier))",
                        fontFamily: "General Sans",
                        color: "#FD6125",
                      },
                      ".MuiFormControl-root": {
                        fontSize: "calc(20px * var(--heightmultiplier))",
                        color: "var(--primaryTextColor)",
                      },
                    }}>Delivery Type</FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={deliveryType}
                    onChange={handleDeliveryRadioChange}
                    sx={{
                      ".MuiTypography-root": {
                        fontSize: "calc(20px * var(--heightmultiplier))"
                      },
                      ".MuiFormControl-root": {
                        fontSize: "calc(20px * var(--heightmultiplier))"
                      },
                      color: "var(--primaryTextColor)",
                      width: "calc(300px * var(--widthmultiplier))",
                      //border:"1px solid var(--primaryTextColor)",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",

                    }}
                  >
                    <FormControlLabel value="Urgent" control={<Radio sx={{ color: "var(--primaryTextColor)" }} />} label="Urgent" />
                    <FormControlLabel value="Scheduled" control={<Radio sx={{ color: "var(--primaryTextColor)" }} />} label="Scheduled" />
                  </RadioGroup>
                </FormControl>
              </div>
              {deliveryType === "Scheduled" ? <div className="BagListSubContent-container__datePicker2">
                <DatePicker
                  format="MM/dd/yyyy hh:mm aa"
                  value={deliveryDate}
                  oneTap={true}
                  onChange={(date) => {
                    setDeliveryDate(date)
                    setSelectDateVisibility(false);
                  }}
                  className='BagListSubContent-container__datePicker2__dateContainer'
                  // open={true}
                  placement="bottom"
                />
              </div> : <div className="BagListSubContent-container__datePicker2">
                {/* <DatePicker
                format="MM/dd/yyyy hh:mm aa"
                value={deliveryDate}
                oneTap={true}
                onChange={(date) => {
                  setDeliveryDate(date)
                  setSelectDateVisibility(false);
                }}
                className='BagListSubContent-container__datePicker2__dateContainer'
                // open={true}
                placement="bottom"
              /> */}
              </div>}
            </div>
          </div>}
      </div>


      <div className="BagListSubContent-container__subContainer">
        <div className="ListDownItems-container">
          <div className="ListDownItems-container__Sr-No"></div>
          <div className="ListDownItems-container__name">Name</div>
          <div className="ListDownItems-container__unit-price">
            {/* Unit Price */}
          </div>
          <div className="ListDownItems-container__weight">Weight</div>
          <div className="ListDownItems-container__Volumetricweight">
            Volumetric Weight
          </div>
          <div className="ListDownItems-container__quantity">Quantity</div>
          <div className="ListDownItems-container__totalWeight">
            Total Weight
          </div>
          <div className="ListDownItems-container__total-price">
            {/* Total Price */}
          </div>
        </div>
        <div className="BagListSubContent-container__subContainer__listDown">
          <div>
            {BagList.map((item, i) => {
              return (
                <ListDownItem
                  item={item}
                  index={i}
                  key={`${item.name} ${i}`}
                  // updateQuantity={handleUpdateItemQuantityInBag}
                  deleteItem={handleDeleteCartItem}
                  SetLoadingVisibility={SetLoadingVisibility}
                  bagList={BagList}
                  setBagList={setBagList}
                  userCookie={userCookie}

                />
              );
            })}
          </div>
        </div>
        <div className="BagListSubContent-container__ListDownItems-container">
          {/* <button className="BagListSubContent-container__ListDownItems-container__place-order"
            onClick={handlePlaceOrderClick}>
            Place Order</button> */}
          <button className="BagListSubContent-container__ListDownItems-container__place-order" 
          // style={cartQuantityUpdated ? null : { backgroundColor: "gray", color: "white" }}
            onClick={ handlePlaceOrderClick}>
            Place Order</button>
          {/* <button className="BagListSubContent-container__ListDownItems-container__update-cart">
            Update Cart</button> */}
          <div className="BagListSubContent-container__ListDownItems-container__total">
            <div className="BagListSubContent-container__ListDownItems-container__total__tag">
              Cart Weight
            </div>
            <div className="BagListSubContent-container__ListDownItems-container__total__value">
              {extraWeight < 0 ? <div className="BagListSubContent-container__ListDownItems-container__total__value__data">
                <div>{totalWeight}g</div> <div style={{ color: "red" }}>Please remove some items</div></div> :
                <div className="BagListSubContent-container__ListDownItems-container__total__value__data"><div>{totalWeight}g</div>
                  <div style={{ color: "red" }}>You can Add {extraWeight}g more weight</div></div>}</div>
          </div>
          {/* <div className="BagListSubContent-container__ListDownItems-container__total">
            <div className="BagListSubContent-container__ListDownItems-container__total__tag">

            </div>
            <div className="BagListSubContent-container__ListDownItems-container__total__value">
              Rs {totalPrice}</div>

            </div> */}

        </div>
      </div>
      {
        <LocationTypeModal deliveryType={deliveryType}
          deliveryDate={deliveryDate}
          setDeliveryDate={setDeliveryDate}
          selectDateVisibility={selectDateVisibility}
          setSelectDateVisibility={setSelectDateVisibility}
          setDeliveryType={setDeliveryType}
        />
      }
    </div>
  );
};

export default BagListSubContent;
