import React, { useContext, useEffect, useRef, useState } from 'react';
import { Drawer, Button, IconButton } from 'rsuite';
import AngleLeftIcon from '@rsuite/icons/legacy/AngleLeft';
import "./SideDrawer.scss";
import { DrawerContext } from '../../context/DrawerContext';
import { CustomerDetailContext } from '../../context/CustomerDetailContext';
import { Form, RadioGroup, Radio, DatePicker } from 'rsuite';
import QuickCheckoutCart from '../../QuickCart/QuickCheckoutCart';
const SideDrawer = ({
  bagList = [],
  userHubLocation,
  handleDeleteCartItem,
  handleUpdateItemQuantityInBag,
  setSelectedSubContent,
  allLocations,
  userCookie, myLocation
}) => {
  const { openDrawer, setOpenDrawer, placement, setOpenDeliveryTypeModal, hubLocation } = useContext(DrawerContext);
  const { customerData, setCustomerData, destLocation, setDestLocation, clientName, setclientName } = useContext(CustomerDetailContext)
  const [btnColor, setBtnColor] = useState(["#FD6125", "#FD6125"]);
  const InputQuantityRef = useRef();
  const [userHubLocationName, setUserHubLocationName] = useState("")
  const [userLocationName, setUserLocationName] = useState("")
  const [deliveryType, setDeliveryType] = useState('Urgent');
  const [selectDateVisibility, setSelectDateVisibility] = useState(false); // Toggle date picker
  const [deliveryDate, setDeliveryDate] = useState(null); // Delivery date state 
  const [avaiblableDestLocations, setAvaiblableDestLocations] = useState({});
  const [orderType, setOrderType] = useState("Self")


  // Calculate total weight
  const calculateTotalWeight = () =>
    bagList.reduce(
      (total, item) => total + (item.quantity * item.weight || 0),
      0
    );

  const handleChangeQuantity = (value, index) => {
    if (value < 1) value = 1; // Prevent negative or zero values
    handleUpdateItemQuantityInBag(index, value);
  };

  useEffect(() => {
    allLocations.map((loc) => {
      if (loc._id === userHubLocation) {
        setUserHubLocationName(loc.location_name)
      }
    })
    allLocations.map((loc) => {
      if (loc._id === myLocation) {
        setUserLocationName(loc.location_name)
      }
    })
  }, [])

  console.log("userHubLocation", userHubLocation)
  console.log("userCookie", userCookie)

  return (
  <><div className='SideDrawer-container'>
    <div className='SideDrawer-container__header'>
      <button onClick={(e)=>{e.stopPropagation(); setOpenDrawer(false)}} className='SideDrawer-container__header__cancel'>X</button>
    </div>
  <QuickCheckoutCart bagList={bagList}
    userHubLocation={userHubLocation}
    handleDeleteCartItem={handleDeleteCartItem}
    handleUpdateItemQuantityInBag={handleUpdateItemQuantityInBag}
    setSelectedSubContent={setSelectedSubContent}
    allLocations={allLocations}
    userCookie={userCookie} myLocation={myLocation} />
    </div>
    {/* <Drawer
      placement={placement}
      open={openDrawer}
      onClose={() => setOpenDrawer(false)}
      size="xs"
    >
      {/* <div className="quickCart-container">
        <Drawer.Header className="quickCart-container__drawer-header">
          <div className="quickCart-container__drawer-header__quick-checkout-and-cart">
            <div className="quickCart-container__drawer-header__quick-checkout-and-cart__quick-checkout">Quick Checkout</div>
            <div className="quickCart-container__drawer-header__quick-checkout-and-cart__cart">
              <button className="quickCart-container__drawer-header__quick-checkout-and-cart__cart__button">Cart</button>
            </div>
          </div>
          <div className="quickCart-container__heading">
            <div className="quickCart-container__heading__inputFiled">
              <div className="quickCart-container__heading__inputFiled__label">Source Location</div>
              <div className="quickCart-container__heading__inputFiled__value">
                <input
                  readOnly
                  value={hubLocation.location_name}
                  //placeholder="Enter Confirm Password"
                  className="quickCart-container__heading__inputFiled__value__input"

                //onChange={(e) => { setUpdatePasswordData({ ...updatePasswordData, ["confirm_password"]: e.target.value }) }}
                /></div>
            </div>
            <div className="quickCart-container__heading__inputFiled">
              <div className="quickCart-container__heading__inputFiled__label">Destination Location</div>
              <div className="quickCart-container__heading__inputFiled__value">
                {orderType !== "Self" ? <select
                  value={destLocation}
                  onChange={(e) => { setDestLocation(e.target.value) }}
                  //defaultValue={destLocation}
                  //placeholder="Enter Confirm Password"
                  className="quickCart-container__heading__inputFiled__value__input"
                >
                  <option value={"Select"}>
                    Select
                  </option>
                  {allLocations.map((option) => {
                    //console.log("option", option._id, option.location_name)
                    if (option.tag_name !== "Office" && option.tag_name !== "Meghalaya")
                      return <option key={option._id} value={[option._id, option.location_name]}>
                        {option.location_name}
                      </option>
                    else {
                      return
                    }
                  })}</select> :
                  <div className="quickCart-container__heading__inputFiled__value__input">
                    {avaiblableDestLocations[myLocation]}
                  </div>}
              </div>
            </div>
          </div>

        </Drawer.Header>
        <div className='quickCart-container__delivery-type'>
          <Form.Group controlId="delivery-type">

            <Form.ControlLabel style={{ color: 'black', fontSize: '14px' }}>
              Delivery Type
            </Form.ControlLabel>
            <RadioGroup
              inline
              value={deliveryType}
              onChange={(value) => {
                setDeliveryType(value);
                setSelectDateVisibility(value === 'Scheduled');
              }}
              style={{ display: 'flex', gap: '1rem', fontSize: '14px' }}
            >
              <div className='quickCart-container__delivery-type__radiobtn'>
                <Radio value="Urgent">Urgent</Radio>
                <Radio value="Scheduled">Scheduled</Radio>
              </div>
            </RadioGroup>


            {selectDateVisibility && (
              <div className='quickCart-container__delivery-type__calander'>
                <DatePicker className='quickCart-container__delivery-type__calander__datepicker'
                  format="mm/dd/yyyy hh:mm aa"
                  value={deliveryDate}
                  onChange={(date) => setDeliveryDate(date)}
                />
              </div>
            )}
          </Form.Group>
        </div>
        <div className="quickCart-container__tableHeader">
          <div className="quickCart-container__tableHeader__drawer-header-title">Name</div>
          <div className="quickCart-container__tableHeader__drawer-header-quantity">Quantity</div>
          <div className="quickCart-container__tableHeader__drawer-header-delete">Delete</div>
        </div>
        <Drawer.Body className="quickCart-container__drawer-body">
          <div className="quickCart-container__drawer-body__item-list">
            {bagList.map((item, i) => (
              <div key={`${item.name}-${i}`} className="quickCart-container__drawer-body__item-list__drawer-item">
                <div className="quickCart-container__drawer-body__item-list__drawer-item__item-name" >{item.name}</div>
                <div className="quickCart-container__drawer-body__item-list__drawer-item__item-quantity">
                  <div className="quickCart-container__drawer-body__item-list__drawer-item__item-quantity__quantity-controls">
                    <button
                      className="quickCart-container__drawer-body__item-list__drawer-item__item-quantity__quantity-controls__minus-plus"
                      //onMouseOver={() => setBtnColor(["white", "#FD6125"])}
                      //onMouseOut={() => setBtnColor(["#FD6125", "#FD6125"])}
                      onClick={() => handleChangeQuantity(item.quantity - 1, i)}
                    >
                      <svg
                        width="30%"
                        height="30%"
                        viewBox="0 0 14 2"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13 1L1 0.999999"
                          stroke={btnColor[0]}
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                      </svg>
                    </button>
                    <input
                      className="quickCart-container__drawer-body__item-list__drawer-item__item-quantity__quantity-controls__quantity-input"
                      // type="number"
                      ref={InputQuantityRef}
                      value={item.quantity}
                      onChange={(e) =>
                        handleChangeQuantity(parseInt(e.target.value), i)
                      }
                    />
                    <button
                      className="quickCart-container__drawer-body__item-list__drawer-item__item-quantity__quantity-controls__minus-plus"
                      //onMouseOver={() => setBtnColor(["#FD6125", "white"])}
                      //onMouseOut={() => setBtnColor(["#FD6125", "#FD6125"])}
                      onClick={() => handleChangeQuantity(item.quantity + 1, i)}
                    >
                      <svg
                        width="30%"
                        height="30%"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7 1L7 13"
                          stroke={btnColor[1]}
                          strokeWidth="4"
                          strokeLinecap="round"
                        />
                        <path
                          d="M13 7L1 7"
                          stroke={btnColor[1]}
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="quickCart-container__drawer-body__item-list__drawer-item__item-quantity__quantity-controls__item-delete">
                  <Button
                    onClick={() => handleDeleteCartItem(i)}
                    className="quickCart-container__drawer-body__item-list__drawer-item__item-quantity__quantity-controls__item-delete"
                  >
                    <img src="deleteDustbin.svg" alt="Delete" />
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </Drawer.Body>

        <Drawer.Footer className="quickCart-container__drawer-footer">


          <div className="quickCart-container__drawer-footer__total-weight">
            Total Weight: {calculateTotalWeight()} g
          </div>
          <div>
            <Button
              appearance="primary"
              className="quickCart-container__drawer-footer__checkout-button"
              onClick={() => {
                setSelectedSubContent("BagList");
                setOpenDrawer(false);
                setOpenDeliveryTypeModal(true)
              }}
            >
              Place Order
            </Button>
          </div>

        </Drawer.Footer>
      </div> 

      <QuickCheckoutCart     bagList = {bagList}
    userHubLocation ={userHubLocation}
    handleDeleteCartItem ={handleDeleteCartItem}
    handleUpdateItemQuantityInBag={handleUpdateItemQuantityInBag}
    setSelectedSubContent={setSelectedSubContent}
    allLocations={allLocations}
    userCookie={userCookie} myLocation ={myLocation}/>
    </Drawer>*/}
    </>
  );
};

export default SideDrawer;


