import React, { useState, useRef } from "react";
import "./ExportButton.scss";

const ExportButton = ({ data }) => {
  const [loading, setLoading] = useState(false);
  const hiddenDownloadLink = useRef(null);

  const csvStructure = [
    "serial_no",
    "name",
    "category",
    "available_quantity",
    "price",
    "weight",
    "v_weight",
    "description",
    "hub_location",
    "image_name",
    "date",
    "added_by",
  ];

  const jsonToCsv = (data, structure) => {
    const header = structure.join(",");
    const rows = data.map((item) =>
      structure.map((key) => item[key] || "").join(",")
    );
    return [header, ...rows].join("\n");
  };

  const handleExport = () => {
    setLoading(true);
    try {
      const jsonData = data;
      const csvData = jsonToCsv(jsonData, csvStructure);
      console.log("csvData", csvData);

      const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);

      if (hiddenDownloadLink.current) {
        hiddenDownloadLink.current.href = url;
        hiddenDownloadLink.current.download = "inventory_data.csv";
        hiddenDownloadLink.current.click();

        URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.error("Error exporting data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <button
        className="export-button"
        onClick={handleExport}
        disabled={loading}
      >
        {loading ? "Exporting..." : "Export"}
      </button>

      <a
        style={{ display: "none" }}
        ref={hiddenDownloadLink}
        download="inventory_data.csv"
      ></a>
    </>
  );
};

export default ExportButton;




// import React, { useState, useRef } from "react";
// import axios from "axios";
// import { backEndIP } from "../configuration";
// import "./ExportButton.scss";

// const ExportButton = ({ data }) => {
//   const [loading, setLoading] = useState(false);
//   const hiddenDownloadLink = useRef(null); 

//   const csvStructure = [
//     "serial_no",
//     "name",
//     "category",
//     "available_quantity",
//     "price",
//     "weight",
//     "v_weight",
//     "description",
//     "hub_location",
//     "image_name",
//     "date",
//     "added_by",
//   ];

//   const jsonToCsv = (data, structure) => {
//     const header = structure.join(",");
//     const rows = data.map((item) =>
//       structure.map((key) => item[key] || "").join(",")
//     );
//     return [header, ...rows].join("\n");
//   };

//   const handleExport = async () => {
//     setLoading(true);
//     try {
//       //const response = data;
  
//       const jsonData = data
  
//       const csvData = jsonToCsv(jsonData, csvStructure);

//       console.log("csvData",csvData)
//       const opts = {
//         suggestedName: "inventory_data.csv", 
//         types: [
//           {
//             description: "CSV Files",
//             accept: { "text/csv": [".csv"] },
//           },
//         ],
//       };
      
//       const handle = await window.showSaveFilePicker(opts);
//       const writable = await handle.createWritable();
  
      
//       await writable.write(new Blob([csvData], { type: "text/csv;charset=utf-8;" }));
//       await writable.close();
//     } catch (error) {
//       console.error("Error exporting data:", error);
//     } finally {
//       setLoading(false);
//     }
//   };
  

//   return (
//     <>
//       <button
//         className="export-button"
//         onClick={handleExport}
//         disabled={loading}
//       >
//         {loading ? "Exporting..." : "Export"}
//       </button>
    
      
//     </>
//   );
// };

// export default ExportButton;
