import { Modal, Button, ButtonToolbar, Form, RadioGroup, Radio, DatePicker, Stack } from 'rsuite';
import React, { useContext } from 'react';
import "./DeliveryTypeModal.scss";
import { DrawerContext } from '../../context/DrawerContext';

const DeliveryTypeModal = ({
  deliveryType,
  deliveryDate,
  setDeliveryDate,
  selectDateVisibility,
  setSelectDateVisibility,
  setDeliveryType,
}) => {
  const { openDeliveryTypeModal, setOpenDeliveryTypeModal } = useContext(DrawerContext);

  const handleOpen = () => {
    console.log("Opening Modal");
    setOpenDeliveryTypeModal(true);
  };

  const handleClose = () => {
    console.log("Closing Modal");
    setOpenDeliveryTypeModal(false);
  };

  const handleDeliveryRadioChange = (value) => {
    console.log("Selected Delivery Type:", value);
    
    if (value === "Urgent") {
      setSelectDateVisibility(false);
    } else {
      setSelectDateVisibility(true);
    }
  };  

  return (
    <>
      {/* <ButtonToolbar>
        <Button onClick={handleOpen}>Open</Button>
      </ButtonToolbar> */}

      <Modal open={openDeliveryTypeModal} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>Select Delivery Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="Modal_body">
            <Form.Group controlId="delivery-type">
              <Form.ControlLabel style={{ color: "#FD6125", fontSize: "20px" }}>
                Delivery Type
              </Form.ControlLabel>
              <RadioGroup
                inline
                value={deliveryType}
                onChange={handleDeliveryRadioChange}
                style={{ display: "flex", gap: "1rem" }}
              >
                <Radio value="Urgent">Urgent</Radio>
                <Radio value="Scheduled">Scheduled</Radio>
              </RadioGroup>
              {selectDateVisibility && (
                <div>
                  <DatePicker
                    format="mm/dd/yyyy hh:mm aa"
                    value={deliveryDate}
                    oneTap={true}
                    onChange={(date) => {
                      console.log("Selected Date:", date);
                      setDeliveryDate(date);
                    }}
                  />
                </div>
              )}
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} appearance="primary">
            Ok
          </Button>
          <Button onClick={handleClose} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeliveryTypeModal;
