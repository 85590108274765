import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import "./ProductItemCard.scss";
import { backEndIP } from "../configuration";

const CardContainer = styled.div`
  position: relative;
  width: calc(260px * var(--widthmultiplier));
  height: calc(345px * var(--heightmultiplier));
  background-color: var(--secondaryColor);
  box-sizing: border-box;
  border-radius: calc(
    10px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2
  );
  // border: 1px solid black;
  font-family: "General Sans";
  margin: calc(15px * var(--heightmultiplier))
    calc(15px * var(--widthmultiplier));
`;
const Container = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: var(--primaryColor);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: calc(30px * var(--heightmultiplier))
    calc(30px * var(--widthmultiplier));
  border-radius: calc(
    10px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2
  );
  z-index: 2;
  &:hover {
    border-radius: calc(
      10px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2
    );
    top: calc(-20px * var(--heightmultiplier));
    // box-shadow: 0px 0px 10px #FD6125;
  }
`;

const Image = styled.img`
  // width: calc(200px * var(--widthmultiplier));
  width: auto;
  height: calc(125px * var(--heightmultiplier));
  background-color: var(--primaryColor);
  box-sizing: border-box;
  border-radius: calc(
    8px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2
  );
`;
const Name = styled.div`
  height: calc(42px * var(--heightmultiplier));
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-overflow: ellipsis;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  margin-top: calc(21px * var(--heightmultiplier));
  font-size: calc(20px * var(--heightmultiplier));
  line-height: calc(20px * var(--heightmultiplier));
  align-self: flex-start;
`;

const Price = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: calc(22px * var(--heightmultiplier));
  line-height: calc(20px * var(--heightmultiplier));
  color: var(--primaryTextColor);
  align-self: flex-start;
  margin-top: calc(11px * var(--heightmultiplier));
  color: "var(--primaryTextColor)";
  opacity: 0.4;
`;

const Instock = styled.div`
font-family: 'General Sans';
font-style: normal;
font-weight: 500;
font-size: calc(16px * var(--heightmultiplier));
line-height: calc(20px * var(--heightmultiplier));
color: var(--primaryTextColor);
align-self:flex-start;
margin-top: calc(11px * var(--heightmultiplier));
rgba($color: var(--primaryTextColor), $alpha: 0.4);
`;

const Button = styled.button`
  width: calc(200px * var(--widthmultiplier));
  height: calc(40px * var(--heightmultiplier));
  background-color: rgba(253, 97, 37, 0.1);
  box-sizing: border-box;
  padding: 0px calc(38px * var(--widthmultiplier));
  border-radius: calc(
    8px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2
  );
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: calc(23px * var(--heightmultiplier));
  color: #fd6125;

  &:hover {
    cursor: pointer;
    background-color: rgba(253, 97, 37);
    color: white;
  }
`;
const StockOutButton = styled.button`
  width: calc(200px * var(--widthmultiplier));
  height: calc(40px * var(--heightmultiplier));
  background-color: rgba(253, 97, 37, 0.1);
  box-sizing: border-box;
  padding: 0px calc(38px * var(--widthmultiplier));
  border-radius: calc(
    8px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2
  );
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: calc(23px * var(--heightmultiplier));
  color: #fd6125;
`;
const ButtonStr = styled.div`
  height: 100%;
  width: auto;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: calc(18px * var(--heightmultiplier));
  line-height: calc(24px * var(--heightmultiplier));
  // border:1px solid black;
  display: flex;
  align-items: center;
`;


const BagImage = ({ cartItem, classes, hoverState }) => {
  return (
    <div className={`${classes ? classes : ""}`}>
      {!hoverState ?
        <svg width="20" height="22" viewBox="0 0 20 22" fill="none" stroke="#FD6125" xmlns="http://www.w3.org/2000/svg">
          <path d="M4 1L1 5V19C1 19.5304 1.21071 20.0391 1.58579 20.4142C1.96086 20.7893 2.46957 21 3 21H17C17.5304 21 18.0391 20.7893 18.4142 20.4142C18.7893 20.0391 19 19.5304 19 19V5L16 1H4Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M1 5H19" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M14 9C14 10.0609 13.5786 11.0783 12.8284 11.8284C12.0783 12.5786 11.0609 13 10 13C8.93913 13 7.92172 12.5786 7.17157 11.8284C6.42143 11.0783 6 10.0609 6 9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
        :

        <svg width="20" height="22" viewBox="0 0 20 22" fill="none" stroke="#FFFFFF" xmlns="http://www.w3.org/2000/svg">
          <path d="M4 1L1 5V19C1 19.5304 1.21071 20.0391 1.58579 20.4142C1.96086 20.7893 2.46957 21 3 21H17C17.5304 21 18.0391 20.7893 18.4142 20.4142C18.7893 20.0391 19 19.5304 19 19V5L16 1H4Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M1 5H19" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M14 9C14 10.0609 13.5786 11.0783 12.8284 11.8284C12.0783 12.5786 11.0609 13 10 13C8.93913 13 7.92172 12.5786 7.17157 11.8284C6.42143 11.0783 6 10.0609 6 9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      }
    </div>
  )
}

const ProductItemCard = ({
  selectedProduct,
  SetSelectedProduct,
  product,
  userCookie,
  handleNotifyAlert,
  SetLoadingVisibility,
  BagList,
  setBagList,
}) => {
  const [cartIconColor, setCartIconColor] = useState("cartBag.svg");
  const [btnColor, setBtnColor] = useState(["#FD6125", "#FD6125"]);
  const [hoverColor, setHoverColor] = useState(false)
  const [quantity, setQuantity] = useState(
    () => BagList.find((item) => item._id === product._id)?.quantity || 0
  );
  const InputQuantityRef = useRef(null);

  useEffect(() => {
    setQuantity(BagList.find((item) => item._id === product._id)?.quantity || 0);
  }, [BagList]);



  const deleteItem = async (idx) => {
    axios({
      url: `${backEndIP}/cart-add/del-item-cart`,
      method: "Delete",
      headers: {
        authorization: userCookie.token,
      },
      data: { index: idx },
    })
      .then((resp) => {
        SetLoadingVisibility(false);
        setBagList(resp.data.cart);
        // setQuantity(newQuantity);
        handleNotifyAlert(
          "notify",
          `${product.name} : removed from cart successfully`,
          "success",
          () => { }
        );
      })
      .catch((err) => {
        SetLoadingVisibility(false);
        const errorMsg = err.response?.message || "Some error occurred";
        window.alert(errorMsg);
      });
  }
  const myProduct = BagList.find((item) => item._id === product._id)

  const handleReduceItemQuantity = () => {
    SetLoadingVisibility(true);
    if (myProduct.quantity === 1) {
      let idx = -1;
      BagList.map((item, index) => {
        if (item._id === product._id) idx = index;
      })
      console.log("idx", idx)
      deleteItem(idx);
    }
    else {
      axios({
        url: `${backEndIP}/cart-add/add-item-cart`,
        method: "POST",
        headers: {
          authorization: userCookie.token,
        },
        data: { id: product._id, quantity: -1 }, // reduce by one
      })
        .then((resp) => {
          SetLoadingVisibility(false);
          setBagList(resp.data.data.cart);
        })
        .catch((err) => {
          SetLoadingVisibility(false);
          const errorMsg = err.response?.message || "Some error occurred";
          window.alert(errorMsg);
        });
    }
  }


  const handleIncreaseItemQuantity = () => {
    SetLoadingVisibility(true);
    axios({
      url: `${backEndIP}/cart-add/add-item-cart`,
      method: "POST",
      headers: {
        authorization: userCookie.token,
      },
      data: { id: product._id, quantity: 1 },// increase by one
    })
      .then((resp) => {
        SetLoadingVisibility(false);
        setBagList(resp.data.data.cart);
      })
      .catch((err) => {
        SetLoadingVisibility(false);
        const errorMsg = err.response?.message || "Some error occurred";
        window.alert(errorMsg);
      });
  }




  const handleAddToCartClick = (e) => {
    e.stopPropagation();
    // updateCart(1);
    handleIncreaseItemQuantity()
  };


  const handleProductClick = () => {
    SetSelectedProduct(product);
  };

 
  const handleItemQuantityInput = () => {
    // const value = e.target.value;
    if (InputQuantityRef.current.value === "" || InputQuantityRef.current.value === "0") {
      let idx = -1;
      BagList.map((item, index) => {
        if (item._id === product._id) idx = index;
      })
      SetLoadingVisibility(true);
      deleteItem(idx);
      setQuantity(null)
      return
    }
    const newQuantity = parseInt(InputQuantityRef.current.value)
    const myProduct = BagList.find((item) => item._id === product._id)
    setQuantity(newQuantity)
    // setQuantity(newQuantity === "" ? null : parseInt(value));
    if (quantity === 0) {
      let idx = -1;
      BagList.map((item, index) => {
        if (item._id === product._id) idx = index;
      })
      SetLoadingVisibility(true);
      deleteItem(idx);
      setQuantity(null)
    }
    if (quantity > 0) {
      // let quantityToUpdate = 0
      // if(product.quantity< newQuantity){

      // }
      SetLoadingVisibility(true);
      axios({
        url: `${backEndIP}/cart-add/add-item-cart`,
        method: "POST",
        headers: {
          authorization: userCookie.token,
        },
        data: { id: product._id, quantity: quantity - myProduct.quantity },// increase by one
      })
        .then((resp) => {
          SetLoadingVisibility(false);
          setBagList(resp.data.data.cart);
        })
        .catch((err) => {
          SetLoadingVisibility(false);
          const errorMsg = err.response?.message || "Some error occurred";
          window.alert(errorMsg);
        });
    }

  };



  useEffect(() => {
    // console.log("quantity", quantity)
    if (InputQuantityRef.current) {
      InputQuantityRef.current.value = quantity
    }
  }, [quantity])

  return (
    <CardContainer
      style={{
        boxShadow:
          selectedProduct === product ? "0px 0px 15px #FD6125" : "none",
          // scale: selectedProduct === product ? "1.06" : "1",
      }}
    >
      <div className="productCardContainer" onClick={handleProductClick}>
        <Image src={`${backEndIP}/${product.image_name}`} alt="product" />
        <Name>{product.name}</Name>
        <Instock>Instock: {parseInt(product.available_quantity)} units</Instock>
        {product.available_quantity > 0 ? (
          quantity > 0 || quantity === null ? (
            <div className="productCardContainer__itemCounter">
              <button
                className="productCardContainer__itemCounter__button"
                onMouseOver={() => setBtnColor(["white", "#FD6125"])}
                onMouseOut={() => setBtnColor(["#FD6125", "#FD6125"])}
                onClick={(e) => {
                  e.stopPropagation();
                  // if (quantity >= 1) updateCart(-1);
                  handleReduceItemQuantity()
                }}
              >
                <svg
                  width="60%"
                  height="10%"
                  viewBox="0 0 14 2"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13 1L1 0.999999"
                    stroke={btnColor[0]}
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </button>
              <input
                className="productCardContainer__itemCounter__input"
                type="number"
                ref={InputQuantityRef}
                defaultValue={quantity}
                // value={quantity}//{quantity === null ? 0 : quantity}
                onChange={(e) => {
                  // console.log(e.target.value);
                  if (e.target.value !== "" && e.target.value !== "0") {
                    const newVal = parseInt(e.target.value)
                    // if (newVal !== 0)
                    setQuantity(newVal)
                  }
                  // else
                  //   setQuantity(0)
                }}
                onKeyDown={(e) => {
                  //console.log(e.key)
                  if (e.key === "Enter") handleItemQuantityInput();
                }}
              // onBlur={handleQuantityValidation}
              />
              <button
                className="productCardContainer__itemCounter__button"
                onMouseOver={() => setBtnColor(["#FD6125", "white"])}
                onMouseOut={() => setBtnColor(["#FD6125", "#FD6125"])}
                onClick={(e) => {
                  e.stopPropagation();
                  // if (quantity < product.available_quantity) updateCart(1);
                  handleIncreaseItemQuantity()
                }}
              >
                <svg
                  width="70%"
                  height="70%"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 1L7 13"
                    stroke={btnColor[1]}
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M13 7L1 7"
                    stroke={btnColor[1]}
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </button>
            </div>
          ) : (
            <Button onClick={handleAddToCartClick} onMouseMove={() => { setHoverColor(true) }}
              onMouseOut={() => { setHoverColor(false) }}>
              <ButtonStr>Add to Bag</ButtonStr>
              {/* <img
                style={{ width: "18px", height: "20px" }}
                src={cartIconColor}
                alt="bagIcon"
              /> */}
              <BagImage hoverState={hoverColor} />
            </Button>
          )
        ) : (
          <StockOutButton style={{ backgroundColor: "lightgrey", color: "white" }}>
            <ButtonStr>Out of Stock</ButtonStr>
          </StockOutButton>
        )}
      </div>
    </CardContainer>
  );
};

export default ProductItemCard;