import { weightLimit } from "../components/configuration";

export const checkWeightLimitFunction=(weight) =>{

    if (weight >= weightLimit) {
        return true
    } else {
        return false
    }
}
