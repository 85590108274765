import axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components';
import ProductItemCard from '../ProductItemCard/ProductItemCard'
import "./CreateOrder.scss"
import { backEndIP } from '../configuration'
import { CustomerDetailContext } from '../../context/CustomerDetailContext';
// import ShopifySubComponent from '../shopify/ShopifySubComponent';
import CustomerDetailsRow from '../CustomerDetailsRow';
import QuickCheckoutCart from '../../QuickCart/QuickCheckoutCart';
import { useNavigate } from 'react-router-dom';

const StockOutButton = styled.button`
width: calc(200px * var(--widthmultiplier));
height: calc(40px * var(--heightmultiplier));
background-color: rgba(253,97,37,0.1);
box-sizing: border-box;
padding:0px calc(38px * var(--widthmultiplier));
border-radius: calc(8px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
border:none;
display:flex;
align-items:center;
justify-content:center;
margin-top: calc(23px * var(--heightmultiplier));
color: #FD6125;

`;
const Button = styled.button`
width: calc(200px * var(--widthmultiplier));
height: calc(40px * var(--heightmultiplier));
background-color: rgba(253,97,37,0.1);
box-sizing: border-box;
padding:0px calc(38px * var(--widthmultiplier));
border-radius: calc(8px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
border:none;
display:flex;
align-items:center;
justify-content:space-between;
color: #FD6125;

&:hover{
    cursor: pointer;
    background-color: rgba(253,97,37);
    color: white;
}
`;
const ButtonStr = styled.div`

height:100%;
width: auto;
font-family: 'General Sans';
font-style: normal;
font-weight: 500;
font-size: calc(18px * var(--heightmultiplier));
line-height: calc(24px * var(--heightmultiplier));
// border:1px solid black;
display: flex;
align-items: center;
`;


const CreateOrder = ({ selectedCategory, userCookie, handleNotifyAlert, SetLoadingVisibility, BagList, setBagList, setCartProductCount,
  setCustomerDetailsPopup, userHubLocation, allLocations, userPermission, cartQuantityUpdated, customerDetailsPopup, setSelectedCategory,   
  handleDeleteCartItem,
  setContentOption,
  setOrderNo,
  setSelectedTab,
  setSelectedSubContent }) => {

  const [selectedProduct, SetSelectedProduct] = useState({});
  const [inventoryItems, setInventoryItems] = useState([]);
  const [cartIconColor, setCartIconColor] = useState("cartBag.svg");
  const { customerData, setCustomerData, destLocation, setDestLocation, clientName, setclientName } = useContext(CustomerDetailContext)
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate()
  // const [quantity, setQuantity] = useState(0);

  useEffect(() => {
    // Fetch category list from backend
    const fetchCategories = async () => {
      try {
        SetLoadingVisibility(true);
        const response = await axios({
          url: backEndIP + "/category/get_category",
          method: "GET",
          headers: {
            authorization: userCookie.token
          },
        });
        console.log("Response", response.data.data)
        //const data = await response.json();
        setCategories(response.data.data); // Assuming backend sends { categories: [...] }
        SetLoadingVisibility(false);
      } catch (error) {
        console.error("Error fetching categories:", error);
        handleNotifyAlert("Failed to fetch categories.");
        SetLoadingVisibility(false);
      }
    };

    fetchCategories();
  }, [SetLoadingVisibility, userCookie]);

  // const InputQuantityRef = useRef(null);
  useEffect(() => {
    // if (InputQuantityRef?.current) {
      // InputQuantityRef.current.value = 1;
      SetLoadingVisibility(true);
      axios({
        url: backEndIP + "/inventory/get-all-items",
        method: "GET",
        headers: {
          authorization: userCookie.token,
          category: selectedCategory
        },
      }).then((data) => {
        //console.log("received=", data);
        setInventoryItems(data.data.data.sort((a, b) => (a.serial_no > b.serial_no ? 1 : -1)));
        SetSelectedProduct(data.data.data.length ? data.data.data[0] : "");
        SetLoadingVisibility(false);

      });
    // }
  }, [selectedCategory, customerDetailsPopup])

  useEffect(() => {
    setInventoryItems(inventoryItems.sort((a, b) => (a.serial_no > b.serial_no ? 1 : -1)))
    //console.log(inventoryItems)
  }, [inventoryItems])



  const handleButtonMouseOver = () => {
    setCartIconColor("cartBagWhite.svg");
  }
  const handleButtonMouseOut = () => {
    setCartIconColor("cartBag.svg");
  }

  const updateCart = () => {
    SetLoadingVisibility(true);
    axios({
      url: backEndIP + "/cart-add/get-cart-items",
      method: "GET",
      headers: {
        authorization: userCookie.token,
      },
      // data: { id: product._id , quantity:1 }
    })
      .then((resp) => {
        SetLoadingVisibility(false);
        console.log(resp.data);
        setBagList(resp.data.data.cart)
      })
      .catch((err) => {
        SetLoadingVisibility(false);
        if (err.response.data.message) window.alert(err.response.data.message);
        else window.alert("some error occurered");
        console.log(err);
      });
  }
  useEffect(()=>{
    if(customerData.name === "" || customerData.email=== ""|| destLocation === "Select"){
      navigate("/create-order")
      setCustomerDetailsPopup(true)
      setSelectedSubContent("Create Order")
      setSelectedTab("Create Order")
    }
  }, [customerData])

  // const handleAddToCartClick = (e) => {
  //   if (InputQuantityRef) {
  //     e.stopPropagation();
  //     SetLoadingVisibility(true);
  //     axios({
  //       url: backEndIP + "/cart-add/add-item-cart",
  //       method: "POST",
  //       headers: {
  //         authorization: userCookie.token
  //       },
  //       data: { id: selectedProduct._id, quantity: parseInt(InputQuantityRef.current.value) }
  //     }).then((resp) => {
  //       // console.log(resp)
  //       SetLoadingVisibility(false);
  //       setBagList(resp.data.data.cart)
  //       // setBagList(...BagList, resp.data.data)
  //       // updateCart()
  //       handleNotifyAlert("notify", `${selectedProduct.name} : ${InputQuantityRef.current.value} units added successfully`, "success", () => { })

  //     }).catch((err) => {
  //       SetLoadingVisibility(false);
  //       if (err.response.data.message)
  //         window.alert(err.response.data.message)
  //       else window.alert("some error occurered")
  //       //console.log(err);
  //     })
  //   }
  // }

  // const handleQuantityInputChange = () => {
  //   if (InputQuantityRef.current.value < 1) {
  //     InputQuantityRef.current.value = 1
  //   }
  // }
  console.log("destLocation", destLocation.split(",")[0])


  return (
    <div className='CreateOrderInventoryShowSubContent-container'>
      <div className='CreateOrderInventoryShowSubContent-container__titleContainer'>
        {customerData.name !== "" && customerData.email !== "" && customerData.phone !== "" && <CustomerDetailsRow setCustomerDetailsPopup={setCustomerDetailsPopup} />}
        <div className='CreateOrderInventoryShowSubContent-container__titleContainer__container'>
          <div className='CreateOrderInventoryShowSubContent-container__titleContainer__container__title'>
            {selectedCategory !== "" ? ` List of Available Products in ${selectedCategory}` :
              "List of Available Products"}
          </div>
          <select
            className="CreateOrderInventoryShowSubContent-container__titleContainer__container__category-dropdown"
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
          >
            <option value="">All Category</option>
            {categories.map((category) => (
              <option key={category.id} value={category.name}>
                {category.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      {/* {clientName !== "TecheagleOMS" ? 
      <ShopifySubComponent
          SetLoadingVisibility={SetLoadingVisibility}
          userCookie={userCookie}
          userPermission={userPermission}
          userHubLocation={userHubLocation}
          myLocation={destLocation}
          handleNotifyAlert={handleNotifyAlert}
          allLocations={allLocations}
        /> :   */}
      <div className='CreateOrderInventoryShowSubContent-container__subContainer'>
        <div className='CreateOrderInventoryShowSubContent-container__subContainer__products-view'>
          {inventoryItems.map((ele) => {
            return <ProductItemCard userCookie={userCookie} selectedProduct={selectedProduct}
              setCartProductCount={setCartProductCount}
              BagList={BagList} setBagList={setBagList}
              SetSelectedProduct={SetSelectedProduct} handleNotifyAlert={handleNotifyAlert}
              SetLoadingVisibility={SetLoadingVisibility}
              product={ele}
              key={`product${ele._id}`} />
          })}
        </div>
        {BagList.length ?
          <div className='CreateOrderInventoryShowSubContent-container__subContainer__product-description'>
            <QuickCheckoutCart
              bagList={BagList}
              setBagList={setBagList}
              userHubLocation={userHubLocation}
              handleDeleteCartItem={handleDeleteCartItem}
              handleNotifyAlert={handleNotifyAlert}
              SetLoadingVisibility={SetLoadingVisibility}
              setSelectedSubContent={setSelectedSubContent}
              allLocations={allLocations}
              userCookie={userCookie}
              myLocation={destLocation.split(",")[0]}
              setContentOption={setContentOption}
              setOrderNo={setOrderNo}
              setSelectedTab={setSelectedTab}
            />
          </div>
          :
          <div className='CreateOrderInventoryShowSubContent-container__subContainer__product-description'>
            <div className='CreateOrderInventoryShowSubContent-container__subContainer__product-description__title'
            >{selectedProduct.name}</div>
            <div className='CreateOrderInventoryShowSubContent-container__subContainer__product-description__descrip'
            >{selectedProduct.description}
            </div>
            {/* <div className='CreateOrderInventoryShowSubContent-container__subContainer__product-description__addToCart'>
              <input className='CreateOrderInventoryShowSubContent-container__subContainer__product-description__addToCart__input'
                type={"number"} ref={InputQuantityRef} min="1" max={selectedProduct.available_quantity}
              //  onChange={handleQuantityInputChange}
              ></input>
              {selectedProduct.available_quantity > 0 && <Button onClick={handleAddToCartClick}
                onMouseOver={handleButtonMouseOver}
                onMouseOut={handleButtonMouseOut}>
                <ButtonStr>
                  Add to Bag
                </ButtonStr>
                <img style={{
                  width: "calc(18px * var(--widthmultiplier))",
                  height: "calc(20px * var(--heightmultiplier))"
                }}
                  src={cartIconColor} alt='bagIcon'></img>
              </Button>}
              {selectedProduct.available_quantity < 1 && <StockOutButton
                style={{ backgroundColor: "lightgrey", color: "white" }}>
                <ButtonStr>Out of Stock</ButtonStr>
              </StockOutButton>}
            </div> */}
          </div>
        }
      </div>
    </div>
  )
}

export default CreateOrder