import React, { useState, useEffect } from "react";
import axios from 'axios';
import InventoryShowSubContent from "../InventoryShowSubContent/InventoryShowSubContent";
import { backEndIP } from '../configuration'
import './HomeContent.scss'

const HomeContent = ({
  SetLoadingVisibility,
  selectedCategory,
  setSelectedCategory,
  userCookie,
  handleNotifyAlert,
  BagList,
  setBagList,
  userHubLocation,
  myLocation,
  allLocations,
  setSelectedSubContent, 
  handleDeleteCartItem, 
  setContentOption,
  setOrderNo,
  setSelectedTab
}) => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    // Fetch category list from backend
    const fetchCategories = async () => {
      try {
        SetLoadingVisibility(true);
        const response = await axios({
          url: backEndIP + "/category/get_category",
          method: "GET",
          headers: {
            authorization: userCookie.token
          },
        });
        // console.log("Response", response.data.data)
        //const data = await response.json();
        setCategories(response.data.data); // Assuming backend sends { categories: [...] }
        SetLoadingVisibility(false);
      } catch (error) {
        // console.error("Error fetching categories:", error);
        handleNotifyAlert("Failed to fetch categories.");
        SetLoadingVisibility(false);
      }
    };

    fetchCategories();
  }, [SetLoadingVisibility, userCookie]);
  // console.log("selectedCategory", selectedCategory)

  return (
    <div className="home-content-container">
      <select
        className="category-dropdown"
        value={selectedCategory}
        onChange={(e) => setSelectedCategory(e.target.value)}
      >
        <option value="">All Category</option>
        {categories.map((category) => (
          <option key={category.id} value={category.name}>
            {category.name}
          </option>
        ))}
      </select>

      {/* Existing InventoryShowSubContent Component */}
      {selectedCategory ? <>
        {categories.map((category) => {
          if (category.name === selectedCategory) {
            if (category.noOfItemsPresent >= 1) {
              return <div className="inventory-content">
                <InventoryShowSubContent
                  SetLoadingVisibility={SetLoadingVisibility}
                  selectedCategory={selectedCategory}
                  userCookie={userCookie}
                  handleNotifyAlert={handleNotifyAlert}
                  BagList={BagList}
                  setBagList={setBagList}
                  userHubLocation={userHubLocation}
                  setSelectedSubContent={setSelectedSubContent}
                  allLocations={allLocations}
                  myLocation={myLocation}
                  handleDeleteCartItem={handleDeleteCartItem}
                  setContentOption={setContentOption}
                setOrderNo={setOrderNo}
                setSelectedTab={setSelectedTab}
                />
              </div>
            } else {
              return <div className="inventory-content">
                <div>No Product Found</div>
              </div>
            }
          }
        })}
      </> :
        <div className="inventory-content">
          <InventoryShowSubContent
            SetLoadingVisibility={SetLoadingVisibility}
            selectedCategory={selectedCategory}
            userCookie={userCookie}
            handleNotifyAlert={handleNotifyAlert}
            BagList={BagList}
            setBagList={setBagList}
            userHubLocation={userHubLocation}
            setSelectedSubContent={setSelectedSubContent}
            allLocations={allLocations}
            myLocation={myLocation}
            handleDeleteCartItem={handleDeleteCartItem}
            setContentOption={setContentOption}
            setOrderNo={setOrderNo}
            setSelectedTab={setSelectedTab}
          />
        </div>
      }

    </div>
  );
};

export default HomeContent;
