import axios from 'axios';
import React, { useEffect, useState } from 'react'
import OrdersListItem from '../OrdersListItem/OrdersListItem';
import "./OrdersListSubContent.scss"
import { backEndIP } from '../configuration'
import { useNavigate } from 'react-router-dom';

const OrdersListSubContent = ({ userCookie, setContentOption, setOrderNo, SetLoadingVisibility, handleNotifyAlert }) => {
  const [OrdersList, setOrdersList] = useState([])
  const [OrdersListCopy, setOrdersListCopy] = useState([])
  const [filterBy, setFilterBy] = useState("ALL")
  const navigate = useNavigate();
  useEffect(() => {
    if (userCookie.token === "" || !userCookie.token) {
      navigate("/");
      return;
    }
    SetLoadingVisibility(true);
    axios({
      url: backEndIP + "/order/orders-list",
      method: "GET",
      headers: {
        authorization: userCookie.token,
      },
      // data: { id: product._id , quantity:1 }
    })
      .then((resp) => {
        //console.log(resp.data);
        SetLoadingVisibility(false);
        setOrdersList(resp.data.data.order);
        setOrdersListCopy(resp.data.data.order)
      })
      .catch((err) => {
        SetLoadingVisibility(false);
        if (err.response.data.message) window.alert(err.response.data.message);
        else window.alert("some error occurered");
        //console.log(err);
      });
  }, [])


  useEffect(()=>{
    console.log("filterBy", filterBy)
    let filteredOrders = [];
    if(filterBy === "ALL"){
      filteredOrders = OrdersList
      // setOrdersListCopy(OrdersList)
    }else if(filterBy === "Delivered"){
      OrdersList.map((order)=>{
        if(order.current_status.name === "Delivered"){
          filteredOrders.push(order)
        }
      })
    } else if(filterBy === "Canceled"){
      OrdersList.map((order)=>{
        if(order.current_status.name === "Canceled"){
          filteredOrders.push(order)
        }
      })
    } else if(filterBy === "Open"){
      OrdersList.map((order)=>{
        if(order.current_status.number <= 7){
          filteredOrders.push(order)
        }
      })
    }
    setOrdersListCopy(filteredOrders)

  }, [filterBy])



  return (
    <div className='OrdersListSubContent-container'>
      <div className='OrdersListSubContent-container__header'>
        <div className='OrdersListSubContent-container__header__titleContainer'> 
        <div className='OrdersListSubContent-container__title'>
          Orders List
        </div>
        <div className='OrdersListSubContent-container__totalItems'>
          Total Orders : {OrdersListCopy.length}
        </div>
        </div>
        <div className='OrdersListSubContent-container__filter'>
          <div className='OrdersListSubContent-container__filter__title'>Filter by : </div>
          <div className='OrdersListSubContent-container__filter__options'>
            <button className='OrdersListSubContent-container__filter__options__item'
              style={{ backgroundColor: filterBy === "ALL" ? "#FD6125" : null, color: filterBy === "ALL" ? "white" : null }}
              onClick={(e) => { setFilterBy("ALL") }}>ALL</button>
            <button className='OrdersListSubContent-container__filter__options__item__open'
              style={{ backgroundColor: filterBy === "Open" ? "#F2D058" : null, color: filterBy === "Open" ? "white" : null }}
              onClick={(e) => { setFilterBy("Open") }}>Open</button>
            <button className='OrdersListSubContent-container__filter__options__item__delivered'
              style={{ backgroundColor: filterBy === "Delivered" ? "#6CCC54" : null, color: filterBy === "Delivered" ? "white" : null }}
              onClick={(e) => { setFilterBy("Delivered") }}>Delivered</button>
            <button className='OrdersListSubContent-container__filter__options__item__cancelled'
              style={{ backgroundColor: filterBy === "Canceled" ? "#F25858" : null, color: filterBy === "Canceled" ? "white" : null }}
              onClick={(e) => { setFilterBy("Canceled") }}>Canceled</button>
          </div>
        </div>
      </div>

      <div className='OrdersListSubContent-container__subContainer'>
        <div className='OrdersListItems-container' >
          <div className='OrdersListItems-container__Sr-No'>S.No</div>
          <div className='OrdersListItems-container__order-no'>Order ID</div>
          <div className='OrdersListItems-container__totalWeight'>Total Weight</div>
          <div className='OrdersListItems-container__total-quantity'>Total Quantity</div>
          {/* <div className='OrdersListItems-container__total-price'>Total Price</div> */}
          <div className='OrdersListItems-container__status'>Status</div>
          <div className='OrdersListItems-container__actions'>Actions</div>
        </div>
        <div className='OrdersListSubContent-container__subContainer__listDown'>
          <div>
            {OrdersListCopy.map((item, i) => {
              return <OrdersListItem item={item} index={i} key={`${item.name} ${i}`}
                setContentOption={setContentOption} setOrderNo={setOrderNo} />
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrdersListSubContent