import React, { useContext, useEffect, useRef, useState } from 'react'
import { DrawerContext } from '../context/DrawerContext';
import { CustomerDetailContext } from '../context/CustomerDetailContext';
import { Button, DatePicker, Form, Radio, RadioGroup } from 'rsuite';
import "./QuickCheckoutCart.scss"
import { backEndIP } from '../components/configuration';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import CartRowItem from './CartRowItem';

const QuickCheckoutCart = ({
    bagList = [],
    setBagList,
    userHubLocation,
    handleDeleteCartItem,
    // handleUpdateItemQuantityInBag,
    handleNotifyAlert,
    SetLoadingVisibility,
    setSelectedSubContent,
    allLocations,
    userCookie, myLocation,
    setContentOption,
    setOrderNo,
    setSelectedTab
}) => {

    const { openDrawer, setOpenDrawer, placement, setOpenDeliveryTypeModal } = useContext(DrawerContext);
    const { payloadDetails, setPayloadDetails, deliveryDate, setDeliveryDate,source_location, deliveryType, setDeliveryType, orderType, setOrderType, customerData, setCustomerData, destLocation, setDestLocation, recievedOn, setRecievedOn, destination_location } = useContext(CustomerDetailContext)
    const { hubLocation, setHubLocation } = useContext(DrawerContext);
    const [btnColor, setBtnColor] = useState(["#FD6125", "#FD6125"]);
    const InputQuantityRef = useRef();
    const [userHubLocationName, setUserHubLocationName] = useState("")
    const [userLocationName, setUserLocationName] = useState("")
    // const [deliveryType, setDeliveryType] = useState('Urgent');
    const [selectDateVisibility, setSelectDateVisibility] = useState(false); // Toggle date picker
    // const [deliveryDate, setDeliveryDate] = useState(dayjs(new Date()).add(1, "day").hour(18).minute(0))
    const [avaiblableDestLocations, setAvaiblableDestLocations] = useState({});
    // const [orderType, setOrderType] = useState("Self")
    const [quantity, setQuantity] = useState(0)
    const navigate = useNavigate()

    useEffect(() => {
        // console.log(deliveryDate, destLocation)
        const tempMyLoc = {}
        allLocations.forEach((loc, i) => {
            if (loc._id === myLocation) {
                tempMyLoc[myLocation] = loc.location_name;
                tempMyLoc[loc.location_name] = loc._id;
            }
            if (allLocations[i]._id === userHubLocation) {
                setHubLocation({ location_id: userHubLocation, location_name: `${allLocations[i].location_name} (${allLocations[i].tag_name})` })
            }
        })
        setAvaiblableDestLocations(tempMyLoc)

    }, [allLocations])


    // Calculate total weight
    const calculateTotalWeight = () =>
        bagList.reduce(
            (total, item) => total + (item.quantity * item.weight || 0),
            0
        );

    useEffect(() => {
        allLocations.map((loc) => {
            if (loc._id === userHubLocation) {
                setUserHubLocationName(loc.location_name)
            }
        })
        allLocations.map((loc) => {
            if (loc._id === myLocation) {
                setUserLocationName(loc.location_name)
            }
        })
    }, [])

    // console.log("userHubLocation", userHubLocation)
    // console.log("userCookie", userCookie)
    const updateCartInput = (newQuantity, id) => {
        setQuantity(newQuantity);
        SetLoadingVisibility(true)
        const updatedCart = bagList.map((item) =>
            item._id === id ? { ...item, quantity: newQuantity } : item
        );
        // console.log("newQuantity",newQuantity)

        axios({
            url: `${backEndIP}/cart-add/update-cart`,
            method: "PUT",
            headers: {
                authorization: userCookie.token,
            },
            data: { cart: updatedCart, quantity: newQuantity },
        })
            .then((response, updatedCart) => {
                //console.log("RESPONSE",newQuantity);
                setBagList(response.data.cart);
                // handleNotifyAlert(
                //   "notify",
                //   `${product.name} : ${newQuantity} unit(s) updated successfully`,
                //   "success",
                //   () => { }
                // );
                SetLoadingVisibility(false)
            })
            .catch((error) => {
                SetLoadingVisibility(false)
                console.error("Error updating cart:", error.response?.data || error);
            });
    };
    const handleChangeQuantity = (value, index) => {
        if (value < 1) value = 1; // Prevent negative or zero values
        updateCartInput(value, index);
    };

    const PlaceUserOrder = () => {
        console.log({
            orderType: orderType,
            cart: bagList,
            destination_location: destLocation.split(",")[0],
            source_location: userHubLocation,
            delivery_date: deliveryDate,
            delivery_type: deliveryType,
            customerData: { ...customerData }
        })
        // SetLoadingVisibility(true);

        axios({
            url: backEndIP + "/order/placed-order",
            method: "POST",
            headers: {
                authorization: userCookie.token,
            },
            data: {
                orderType: orderType,
                cart: bagList,
                destination_location: orderType === "Self"?destination_location:destLocation.split(",")[0],
                source_location: orderType === "Self"?source_location:userHubLocation,
                delivery_date: deliveryDate,
                delivery_type: deliveryType,
                customerData: { ...customerData }
            }
            // data: { id: product._id , quantity:1 }
        })
            .then((resp) => {
                SetLoadingVisibility(false);
                console.log(resp.data.order_no);
                setBagList(resp.data.cart);
                // setCartQuantityUpdated(true);
                handleNotifyAlert("notify", "Order placed succesfully", "success", () => { })
                // setDestLocation("Select")
                // setDeliveryType("")
                // setCustomerData({ name: "", email: "", phoneNo: "" })
                // setOrderType("Self")
                setSelectDateVisibility(false)
                if(userCookie.permission === "Granted"){
                    navigate("/manage-orders")
                    setSelectedSubContent("Manage Orders")
                    setSelectedTab("Manage Orders")
                } else {
                    setOrderNo(resp.data.order_no);
                    setContentOption("SpecficOrder")
                    // navigate(`/order/${resp.data.order_no}`)
                    setSelectedSubContent("SpecficOrder")
                    setSelectedTab("My Orders")
                }
                
            })
            .catch((err) => {
                SetLoadingVisibility(false);
                console.log(err);
                // if (err.response.data.message) {
                //     // window.alert(err.response.data.message);
                //     handleNotifyAlert("notify", err.response.data.message, "failed", () => { })
                // }
                // else handleNotifyAlert("notify", "some error occurered", "failed", () => { });
                console.log(err);
            });
    }
    const handlePlaceOrderClick = () => {
        //  console.log(phoneNoElementRef.current.value
        if (orderType === "Self") {
            //   if (userCookie.permission === "Granted") {
            //     if ( deliveryType !== "") {
            //       handleNotifyAlert("alert", `Are you sure you want to place order for Destination location : ${avaiblableDestLocations[myLocation]}?`, "alert", PlaceUserOrder)
            //     }
            //     else if (deliveryType === "") {
            //       handleNotifyAlert("notify", `Please select "delivery type" for the order`, "alert", () => { })
            //     }
            //     else {
            //       SetLoadingVisibility(false);
            //       handleNotifyAlert("notify", "Please update Cart first", "alert", () => { })
            //     }
            //   } else {
            // setDestLocation(myLocation)
            if (deliveryType !== "") {
                handleNotifyAlert("alert", `Are you sure you want to place order for Destination location : ${avaiblableDestLocations[myLocation]}?`, "alert", PlaceUserOrder)
            }
            else if (deliveryType === "") {
                handleNotifyAlert("notify", `Please select "delivery type" for the order`, "alert", () => { })
            }
            else {
                SetLoadingVisibility(false);
                handleNotifyAlert("notify", "Please update Cart first", "alert", () => { })
            }
            //   }

        } else {
            if (customerData.name === "" && customerData.email === "" && customerData.phoneNo === "") {
                handleNotifyAlert("notify", "Please fill all the customers details", "alert", () => { })
              } else if (customerData.phoneNo.slice(3).length !== 10) {
                // setPhoneNoError(true)
                handleNotifyAlert("notify", "Enter Vaild Phone no", "alert", () => { })
              } 
              else {
                // const tempCustomerData ={ ...customerData, phoneNo: `+91${phoneNoElementRef.current.value}` }
                // setCustomerData(tempCustomerData)
                if (destLocation !== "Select" && deliveryType !== "") {
                  console.log("customerData", customerData)
                  handleNotifyAlert("alert", `Are you sure you want to place order for Destination location : ${destLocation.split(",")[1]}?`, "alert", PlaceUserOrder)
                }
                else if (destLocation === "Select") {
                  handleNotifyAlert("notify", "Please select destination Location", "alert", () => { })
                }
                else if (deliveryType === "") {
                  handleNotifyAlert("notify", `Please select "delivery type" for the order`, "alert", () => { })
                }
                else {
                  SetLoadingVisibility(false);
                  handleNotifyAlert("notify", "Please update Cart first", "alert", () => { })
                }
              }
        }
    }

    // console.log("bagList", bagList)

    return (

        <div className="quickCart-container">
            {/* <Drawer.Header className="quickCart-container__drawer-header"> */}
            <div className="quickCart-container__quick-checkout-and-cart">
                <div className="quickCart-container__quick-checkout-and-cart__quick-checkout">Quick Checkout</div>
                <div className="quickCart-container__quick-checkout-and-cart__cart">
                    <div className="quickCart-container__quick-checkout-and-cart__cart__viewBtn-container">
                        <button
                            // appearance="primary"
                            className="quickCart-container__quick-checkout-and-cart__cart__view-button"
                            onClick={() => {
                                setSelectedSubContent("BagList");
                                setOpenDrawer(false);
                                // setOpenDeliveryTypeModal(true)
                            }}
                        >
                            View Cart
                        </button>
                    </div>
                </div>
            </div>
            <div className="quickCart-container__heading">
                <div className="quickCart-container__heading__inputFiled">
                    <div className="quickCart-container__heading__inputFiled__label">Source Location</div>
                    <div className="quickCart-container__heading__inputFiled__value">
                        <input
                            readOnly
                            value={hubLocation.location_name}

                            //placeholder="Enter Confirm Password"
                            className="quickCart-container__heading__inputFiled__value__input"

                        //onChange={(e) => { setUpdatePasswordData({ ...updatePasswordData, ["confirm_password"]: e.target.value }) }}
                        /></div>

                </div>
                <div className="quickCart-container__heading__inputFiled">
                    <div className="quickCart-container__heading__inputFiled__label">Destination Location</div>
                    <div className="quickCart-container__heading__inputFiled__value">
                        {orderType !== "Self" ? <select
                            value={destLocation}
                            onChange={(e) => { setDestLocation(e.target.value) }}
                            //defaultValue={destLocation}
                            //placeholder="Enter Confirm Password"
                            className="quickCart-container__heading__inputFiled__value__input"
                        >
                            <option value={"Select"}>
                                Select
                            </option>
                            {allLocations.map((option) => {
                                //console.log("option", option._id, option.location_name)
                                if (option.tag_name !== "Office" && option.tag_name !== "Meghalaya")
                                    return <option key={option._id} value={[option._id, option.location_name]}>
                                        {option.location_name}
                                    </option>
                                else {
                                    return
                                }
                            })}</select> :
                            <div className="quickCart-container__heading__inputFiled__value__input">
                                {avaiblableDestLocations[myLocation]}
                            </div>}
                    </div>
                </div>
            </div>

            {/* </Drawer.Header> */}
            <div className='quickCart-container__delivery-type'>
                <Form.Group controlId="delivery-type">
                    <div className='quickCart-container__delivery-type__container'>
                        <Form.ControlLabel style={{ fontSize: '16px' }} className='quickCart-container__delivery-type__container__title'>
                            Delivery Type
                        </Form.ControlLabel>
                        <RadioGroup
                            inline
                            value={deliveryType}
                            onChange={(value) => {
                                setDeliveryType(value);
                                // setSelectDateVisibility(value === 'Scheduled');
                            }}
                            style={{ display: 'flex', gap: '1rem', fontSize: '14px' }}
                        >
                            <div className='quickCart-container__delivery-type__radiobtn'>
                                <Radio value="Urgent">Urgent</Radio>
                                <Radio value="Scheduled">Scheduled</Radio>
                            </div>
                        </RadioGroup>
                    </div>


                    {deliveryType === "Scheduled" &&
                        (<div className='quickCart-container__delivery-type__calander'>
                            <DatePicker className='quickCart-container__delivery-type__calander__datepicker'
                                format="MM/dd/yyyy hh:mm aa"
                                oneTap={true}
                                value={deliveryDate}
                                onChange={(date) => setDeliveryDate(date)}
                            />
                        </div>)
                    }
                </Form.Group>
            </div>
            <div className="quickCart-container__tableHeader">
                <div className="quickCart-container__tableHeader__drawer-header-title">Name</div>
                <div className="quickCart-container__tableHeader__drawer-header-quantity">Quantity</div>
                <div className="quickCart-container__tableHeader__drawer-header-delete">Delete</div>
            </div>
            <div className="quickCart-container__drawer-body">
                <div className="quickCart-container__drawer-body__item-list">
                    {bagList.map((cartitem, i) => (
                        <CartRowItem item={cartitem} i={i} bagList={bagList} setBagList={setBagList} SetLoadingVisibility={SetLoadingVisibility} userCookie={userCookie} handleDeleteCartItem={handleDeleteCartItem} />
                    ))}
                </div>
            </div>

            <div className="quickCart-container__drawer-footer">


                <div className="quickCart-container__drawer-footer__total-weight">
                    Total Weight: {calculateTotalWeight()} g
                </div>
                <div className="quickCart-container__drawer-footer__btn-container">
                    <button
                        // appearance="primary"
                        className="quickCart-container__drawer-footer__checkout-button"
                        onClick={() => {
                            // setSelectedSubContent("BagList");
                            // setOpenDrawer(false);
                            // setOpenDeliveryTypeModal(true)
                            handlePlaceOrderClick()
                        }}
                    >
                        Place Order
                    </button>
                </div>

            </div>
        </div>

    )
}

export default QuickCheckoutCart