import React from "react";
import "./BulkUpload.scss";
import ExportButton from "../CsvJsonConverter/ExportButton";
import TemplateButton from "../CsvJsonConverter/TemplateButton";
import ImportInventory from "../CsvJsonConverter/ImportInventory";
import ImportCategory from "../CsvJsonConverter/ImportCategory";

const BulkUpload = ({ select, userCookie, handleNotifyAlert, listItems }) => {
  return (
    <div className="BulkUpload-container__subContainer__inputFiled">
      <div className="BulkUpload-container__subContainer__inputFiled__label">
        Bulk Transfer<div style={{ color: "red" }}> *</div>
      </div>
      {select === "Inventory List" && <ExportButton data={listItems} />}
      {(select === "Add Inventory" || select === "") && <TemplateButton />}

      {(select === "Add Inventory" || select === "") && (
        <ImportInventory
          userCookie={userCookie}
          handleNotifyAlert={handleNotifyAlert}
        />
      )}


      {select === "Add Categories" && (
        <ImportCategory
          userCookie={userCookie}
          handleNotifyAlert={handleNotifyAlert}
        />
      )}

    </div>
  );
};

export default BulkUpload;
