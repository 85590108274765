import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Drawer, RadioGroup, Radio, ButtonToolbar, Button, IconButton, Placeholder } from 'rsuite';
import './AdminHomePage.scss'
import Spinner from 'react-bootstrap/Spinner'
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import io from 'socket.io-client';
import CategoryListItem from '../CategoryListItem/CategoryListItem';
import InventoryShowSubContent from '../InventoryShowSubContent/InventoryShowSubContent';
import styled from 'styled-components';
import UserDropDown from '../UserDropDown/UserDropDown';
import OrderListSubContent from '../OrdersListSubContent/OrdersListSubContent';
import BagListSubContent from '../BagListSubContent/BagListSubContent';
import OrdersListSubContent from '../OrdersListSubContent/OrdersListSubContent';
import SpecificOrderSubContent from '../SpecificOrderSubContent/SpecificOrderSubContent';
import InventoryAdd from '../InventoryAddFrom/InventoryAddFormPage';
// import SideDrawer from '../SidebarDrawer/SideDrawer';


// const socket = io("https://ws5002.dineshborse.in", { transports: ["websocket", "polling"] });

const BagButtonDiv = styled.div`
width: calc(101px * var(--widthmultiplier));
height: calc(45px * var(--heightmultiplier));
display:flex;
align-items:center;
justify-content:space-between;
background-color: var(--primaryColor);
border-radius: calc(10px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2);
padding: calc(0px * var(--heightmultiplier)) calc(18px * var(--widthmultiplier));
&:hover{
    cursor: pointer;
}
`


const AdminHomePage = ({ userCookie, setuserCookie, themeGlobal, userName }) => {
    const [userIconClicked, setUserIconClicked] = useState(false);
    const [isSocketConnected, setIsSocketConnected] = useState(false);
    const [lastPong, setLastPong] = useState(null);
    const navigate = useNavigate();
    
    

    document.body.style.fontSize = ((window.innerHeight / 1080) * 16).toString() + "px";
    const [SizeMultiplier, setSizeMultiplier] = useState({
        "--widthmultiplier": 1,
        "--heightmultiplier": 1,
        height: window.innerHeight.toString() + "px",
        width: window.innerWidth.toString() + "px"
    });

    const [selectedCategory, setSelectedCategory] = useState("")
    const [selectedSubContent, setSelectedSubContent] = useState("")
    const [currentOrderNo, setCurrentOrderNo] = useState(0);
    const [drawerOpen, setDrawerOpen] = useState(false);

    useEffect(() => {
        //  console.log(userCookie.token)
        if (userCookie.token === "" || !userCookie.token) {
            navigate("/")
        }

    }, [userCookie.token])

 


    // useEffect(() => {
    //     socket.on('connect', () => {
    //         setIsSocketConnected(true);
    //         socket.emit("upgrade")
    //     });

    //     socket.on('disconnect', () => {
    //         setIsSocketConnected(false);
    //     });

    //     socket.on('pong', () => {
    //         setLastPong(new Date().toISOString());
    //     });

    //     // socket.on()

    //     return () => {
    //         socket.off('connect');
    //         socket.off('disconnect');
    //         socket.off('pong');
    //     };
    // }, []);



    const categories = ["Medicines"]

    const handleSubContentSelect = (optionName) => {
        if (optionName !== "Logout" && optionName !== "") {
            setSelectedCategory("");
            setSelectedSubContent(optionName);

        }
        console.log(optionName);
        setUserIconClicked(false);

        if (optionName === "Logout") {
            setuserCookie("token", "", { path: "/" })
        }
    }
    useEffect(() => {
        setSelectedSubContent(selectedCategory);
        // setUserIconClicked(false);
    }, [selectedCategory])
    return (
        <div className='home-page-container'>
            <div className='home-sidebar'>
                <div className='home-sidebar__logodiv-border'></div>
                <div className='home-sidebar__Categories-container'>
                    <div className='home-sidebar__Categories-container__Title'
                        onClick={() => { setSelectedCategory(""); setSelectedSubContent("") }}>Categories</div>
                    {categories.map((cat) => {
                        return <CategoryListItem categoryName={cat} key={`category${cat}`} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} />
                    })}
                </div>
            </div>
            <div className='home-main-map'>
                <div className='home-main-map__topInfobar'>
                    <div className='home-main-map__search-container'>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                        >
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5 11C5 7.691 7.691 5 11 5C14.309 5 17 7.691 17 11C17 14.309 14.309 17 11 17C7.691 17 5 14.309 5 11ZM20.707 19.293L17.312 15.897C18.365 14.543 19 12.846 19 11C19 6.589 15.411 3 11 3C6.589 3 3 6.589 3 11C3 15.411 6.589 19 11 19C12.846 19 14.543 18.365 15.897 17.312L19.293 20.707C19.488 20.902 19.744 21 20 21C20.256 21 20.512 20.902 20.707 20.707C21.098 20.316 21.098 19.684 20.707 19.293Z"
                                fill="var(--primaryTextColor)" />
                        </svg>
                        <input className='home-main-map__search-container__search'
                            type={"text"} placeholder={"Search products here"}></input>
                    </div>

                    <div className='home-main-map__topInfobar__user'>
                        <BagButtonDiv onClick={() => {
                          
                            setSelectedSubContent("BagList");
                        }}>
                            <div className='home-main-map__topInfobar__user__bagStr'>Bag</div>
                            <img style={{
                                width: "calc(18px * var(--widthmultiplier))",
                                height: "calc(20px * var(--heightmultiplier))"
                            }}
                                src={"cartBag.svg"} alt='bagIcon'></img>
                        </BagButtonDiv>
                       
                        <div className='home-main-map__topInfobar__user__proImg'
                            onClick={() => { setUserIconClicked(true) }}>
                            {userName ? userName[0].toUpperCase() : ""}
                        </div>
                        <img className='home-main-map__topInfobar__user__img'
                            src='userDownArrow.svg'></img>
                    </div>
                </div>
                <div className='home-main-map__content'>
                    {selectedSubContent === "" && <InventoryShowSubContent selectedCategory={selectedCategory} userCookie={userCookie} />}
                    {selectedSubContent === "Medicines" && <InventoryShowSubContent userCookie={userCookie} selectedCategory={selectedCategory} />}
                    {selectedSubContent === "Orders" && <OrderListSubContent userCookie={userCookie} setContentOption={handleSubContentSelect} setOrderNo={setCurrentOrderNo} />}
                    {selectedSubContent === "SpecficOrder" && <SpecificOrderSubContent userCookie={userCookie} setContentOption={handleSubContentSelect} currentOrderNo={currentOrderNo} />}
                    {selectedSubContent === "BagList" && <BagListSubContent userCookie={userCookie} />}
                    {selectedSubContent === "Settings" && <InventoryAdd userCookie={userCookie} />}
                </div>
                <div className='home-main-map__footer-container'>
                    <div className='home-main-map__footer-container__copyright'>
                        <img className='home-main-map__footer-container__copyright__company-logo'
                            src={themeGlobal.EagleLogo}
                        ></img>
                        <div className='home-main-map__footer-container__copyright__title'>
                            Copyright 2022. TechEagle Innovations Pvt.Ltd.
                        </div>
                    </div>
                </div>
            </div>
            {userIconClicked && <UserDropDown userCookie={userCookie} setOpenModal={setUserIconClicked}
                setContentOption={handleSubContentSelect} />}

        

        </div>



    )
}

export default AdminHomePage;