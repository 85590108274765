import React, { useContext } from 'react'
import { CustomerDetailContext } from '../context/CustomerDetailContext'
import "./CustomerDetailsRow.scss"
const CustomerDetailsRow = ({ setCustomerDetailsPopup }) => {
    const { customerData, setCustomerData, destLocation, setDestLocation, clientName, setclientName } = useContext(CustomerDetailContext)

    return (
        <div className='CustomerDetailsRow-container'>
            <div className="CustomerDetailsRow-container__info">
                <div className="CustomerDetailsRow-container__info__label">Customer Details</div>
                <div className="CustomerDetailsRow-container__info__customerInput">
                    <div className="CustomerDetailsRow-container__info__customerInput__label">Name :</div>
                    <div className="CustomerDetailsRow-container__info__customerInput__value">
                        <input type="text" value={customerData.name} placeholder="Enter name" readOnly
                            className="CustomerDetailsRow-container__info__customerInput__value__input"
                        />
                    </div>
                </div>
                <div className="CustomerDetailsRow-container__info__customerInput" >
                    <div className="CustomerDetailsRow-container__info__customerInput__label">Email : </div>
                    <div className="CustomerDetailsRow-container__info__customerInput__value" >
                        <input type="email" value={customerData.email} placeholder="Enter name" readOnly
                            className="CustomerDetailsRow-container__info__customerInput__value__input"
                        />
                    </div>
                </div>
                <div className="CustomerDetailsRow-container__info__customerInput">
                    <div className="CustomerDetailsRow-container__info__customerInput__label">Phone :</div>
                    <div className="CustomerDetailsRow-container__info__customerInput__value">
                        {/* <input
                            type="text"
                            placeholder="+91"

                            className="CustomerDetailsRow-container__info__customerInput__value__input1"
                            readOnly
                        /> */}
                        <div className="CustomerDetailsRow-container__info__customerInput__value__static"> +91</div>
                        <input
                            // ref={phoneNoElementRef}
                            type="text" // Change to text for better control
                            value={customerData.phoneNo.replace("+91", "")} // Display only the number
                            // style={{ borderColor: PhoneNoError ? "red" : "var(--primaryTextColor)" }}
                            placeholder="Enter Phone no"
                            className="CustomerDetailsRow-container__info__customerInput__value__input"
                            readOnly
                        />

                    </div>
                </div>
                <div className="CustomerDetailsRow-container__info__customerInput" >
                <div className="CustomerDetailsRow-container__info__customerInput__label">
                    <div className="CustomerDetailsRow-container__info__customerInput__label__cont">Destination Location</div>:
                    </div>
                    <div className="CustomerDetailsRow-container__info__customerInput__value" >
                        <input type="email"
                            value={destLocation.split(",")[1]}
                            // style={{ color: emailError === true ? "red" : "var(--primaryTextColor)" }}
                            placeholder="Enter Email"
                            className="CustomerDetailsRow-container__info__customerInput__value__input"
                            // onChange={(e) => {
                            //   setCustomerData({ ...customerData, "email": e.target.value })
                            //   setEmailError(false)
                            //   // setCheck("")
                            // }}
                            readOnly
                        /></div>
                </div>
            </div>
            <button className='CreateOrderInventoryShowSubContent-container__editCustomer' onClick={() => { setCustomerDetailsPopup(true) }}>Edit</button>
        </div>
    )
}

export default CustomerDetailsRow