import dayjs from 'dayjs';
import React, { useContext, useEffect, useRef, useState } from 'react'
import "./SelfOrderModel.scss"
import { CustomerDetailContext } from '../../context/CustomerDetailContext';
import { useNavigate } from 'react-router-dom';
import { DatePicker, Form, Radio, RadioGroup, Stack } from 'rsuite';
import axios from 'axios';
import { backEndIP } from '../configuration';
import { checkWeightLimitFunction } from '../../Utility/checkWeightLimitFunction';
const SelfOrderModel = ({
    userHubLocation,
    myLocation,
    allLocations,
    setSelfOrderDetailsPopup,
    handleNotifyAlert,
    SetLoadingVisibility,
    userCookie
}) => {
    const phoneNoElementRef = useRef()

    const [avaiblableDestLocations, setAvaiblableDestLocations] = useState({});
    const { payloadDetails, setPayloadDetails, source_location, set_source_location, destination_location, set_destination_location, deliveryDate, setDeliveryDate, orderType, setOrderType, deliveryType, setDeliveryType, orderDeliveryType, setOrderDeliveryType, sourceData, setSourceData, destinationData, setDestinationData, customerData, setCustomerData, destLocation, setDestLocation, recievedOn, setRecievedOn } = useContext(CustomerDetailContext)
    const [emailError, setEmailError] = useState(false)
    const [PhoneNoError, setPhoneNoError] = useState(false)
    const [hubLocation, setHubLocation] = useState({ location_id: userHubLocation, location_name: "" })
    const [orderNumber, setOrderNumber] = useState(0);
    // const [orderPayload, setOrderPayload] = useState(0);
    const [userData, setUserData] = useState("")
    // const [source_location, set_source_location] = useState("");
    // const [destination_location, set_destination_location] = useState("");
    const [payloadTypeError, setPayloadTypeError] = useState("");
    const [destinationLocationError, setDestinationLocationError] = useState("");
    const [sourceLocationError, setSourceLocationError] = useState("");
    const [payloadWeightError, setPayloadWeightError] = useState("");
    const [selectDateVisibility, setSelectDateVisibility] = useState("");
    const [recievedOnError, setRecievedOnError] = useState("")
    const [orderDeliveryTypeError, setOrderDeliveryTypeError] = useState("");
    const [deliveryTypeError, setDeliveryTypeError] = useState("");
    const emailFormate = /^\w+([\.-_]?\w+)*@\w*(\.\w{2,3})+$/
    const [errorCheck, setErrorCheck] = useState("")
    const navigate = useNavigate();
    useEffect(() => {
        if (userCookie.token === "" || !userCookie.token) {
            navigate("/");
            return;
        }
        if (userCookie.token) {
            axios({
                url: backEndIP + "/user/verifytoken",
                method: "GET",
                headers: {
                    authorization: userCookie.token
                },
            }).then(async (loginData) => {
                console.log("loginData", loginData);
                setUserData(loginData.data)
            }).catch((err) => {
                console.log("err", err)
            })

        }
        else {
            navigate("/")
        }
    }, [])
    useEffect(() => {
        set_source_location(
            (orderDeliveryType === "POC" || orderDeliveryType === "Test")
                ? sourceData
                : orderDeliveryType === "Diagnostics(Reverse)"
                    ? destLocation.split(",")[0]
                    : userHubLocation
        );

        set_destination_location(
            (orderDeliveryType === "POC" || orderDeliveryType === "Test")
                ? destinationData
                : orderDeliveryType === "Diagnostics(Reverse)"
                    ? userHubLocation
                    : destLocation.split(",")[0]
        );
    }, [orderDeliveryType, sourceData, destLocation, userHubLocation, destinationData]);

    useEffect(() => {
        // console.log(deliveryDate, destLocation)
        const tempMyLoc = {}
        allLocations.forEach((loc, i) => {
            if (loc._id === myLocation) {
                tempMyLoc[myLocation] = loc.location_name;
                tempMyLoc[loc.location_name] = loc._id;
            }
            if (allLocations[i]._id === userHubLocation) {
                setHubLocation({ location_id: userHubLocation, location_name: `${allLocations[i].location_name} (${allLocations[i].tag_name})` })
            }
        })
        setAvaiblableDestLocations(tempMyLoc)

    }, [allLocations])

    const PlaceUserOrder = () => {
        console.log({
            orderType: orderType,
            cart: [],
            destination_location: destination_location,
            source_location: source_location,
            delivery_date: deliveryDate,
            delivery_type: deliveryType,
            payloadDetails: payloadDetails,
            order_delivery_type: orderDeliveryType,
            hubLocation: userHubLocation,
            customerData: { name: userData.userName, email: userData.userEmail, phoneNo: userData.phoneNumber }
        })
        SetLoadingVisibility(true);

        axios({
            url: backEndIP + "/order/placed-manual-order",
            method: "POST",
            headers: {
                authorization: userCookie.token,
            },
            data: {
                // orderType: orderType,
                destination_location: destination_location,
                source_location: source_location,
                delivery_date: deliveryDate,
                delivery_type: deliveryType,
                orderNo: orderNumber,
                recievedOn: orderDeliveryType === "Diagnostics(Reverse)" ? recievedOn : orderType,
                order_delivery_type: orderDeliveryType,
                payloadDetails: payloadDetails,
                hubLocation: userHubLocation,
                customerData: { name: userData.userName, email: userData.userEmail, phoneNo: userData.phoneNumber }
            }
            // data: { id: product._id , quantity:1 }
        })
            .then((resp) => {
                SetLoadingVisibility(false);
                // console.log(resp.data);
                handleNotifyAlert("notify", "Order placed succesfully", "success", () => { })
                setDestLocation("Select")
                setDeliveryType("")
                setCustomerData({ name: "", email: "", phoneNo: "" })
                setOrderType("Self")
                setSelectDateVisibility(false)
                setOrderNumber(0);
                setRecievedOn("");
                setOrderDeliveryType("Select")
                setDestinationData({ name: "", lat: "", long: "" })
                setSourceData({ name: "", lat: "", long: "" })
                setPayloadDetails({ type: "", description: "", weight: 0 })
            })
            .catch((err) => {
                SetLoadingVisibility(false);
                if (err.response.data.message) {
                    // window.alert(err.response.data.message);
                    handleNotifyAlert("notify", err.response.data.message, "failed", () => { })
                }
                else handleNotifyAlert("notify", "some error occurered", "failed", () => { });
                //console.log(err);
            });
    }
    const handlePlaceOrderClick = () => {
        //  console.log(phoneNoElementRef.current.value
        console.log("working", handleSelfOrderDetails());
        if (!handleSelfOrderDetails())
            return;
        console.log("working");
        if (orderType === "Self") {
            // console.log(phoneNoElementRef.current.value)
            // if (customerData.name === "" && customerData.email === "" && phoneNoElementRef === "" && recievedOn === "") {
            //     handleNotifyAlert("notify", "Please fill all the customers details", "alert", () => { })
            // } else if (phoneNoElementRef.current.value.length !== 10) {
            //     setPhoneNoError(true)
            //     handleNotifyAlert("notify", "Enter Vaild Phone no", "alert", () => { })
            // } 
            // else if (customerData.email.match(emailFormate) === null) {
            //     setEmailError(true)
            //     handleNotifyAlert("notify", "Enter User's Vaild Email", "alert", () => { })
            // }
            // else {
            // const tempCustomerData ={ ...customerData, phoneNo: `+91${phoneNoElementRef.current.value}` }
            // setCustomerData(tempCustomerData)
            console.log("destination_location", destination_location);
            if (destination_location !== "Select" && deliveryType !== "") {
                console.log("customerData", customerData)
                handleNotifyAlert("alert", `Are you sure you want to place order for Destination location : ${typeof destination_location === "string" ? destLocation.split(",")[1] : destination_location.name}?`, "alert", PlaceUserOrder)
            }
            else if (destination_location === "Select") {
                handleNotifyAlert("notify", "Please select destination Location", "alert", () => { })
                setSelfOrderDetailsPopup(true);
            }
            else if (deliveryType === "") {
                handleNotifyAlert("notify", `Please select "delivery type" for the order`, "alert", () => { })
                setSelfOrderDetailsPopup(true);
            }
            // else {
            //     SetLoadingVisibility(false);
            //     handleNotifyAlert("notify", "Please update Cart first", "alert", () => { })
            // }
        }
        // }
    }
    const allrecievedOn = [
        "Whatsapp", "Other", "Email",
        // "Shopify"
    ]
    const handleSelfOrderModelClose = () => {
        setSelfOrderDetailsPopup(false);
        // setDestLocation("Select")
        // setDeliveryType("")
        // setCustomerData({ name: "", email: "", phoneNo: "" })
        // setOrderType("Self")
        // setSelectDateVisibility(false)
        // setOrderNumber(0);
        // setRecievedOn("");
        // setOrderDeliveryType("Select")
        // setDestinationData({ name: "", lat: "", long: "" })
        // setSourceData({ name: "", lat: "", long: "" })
        // setPayloadDetails({ type: "", description: "", weight: 0 })
        // setPayloadTypeError("");
        // setDestinationLocationError("");
        // setSourceLocationError("");
        // setPayloadWeightError("");
        // setSelectDateVisibility("");
        // setRecievedOnError("")
        // setOrderDeliveryTypeError("");
    }

  // Constants for validation
const ORDER_TYPES = {
    DIAGNOSTICS_REVERSE: "Diagnostics(Reverse)",
    UNLOCKING_FLIGHT: "Unlocking Flight",
    POC: "POC",
    TEST: "Test",
    SELECT: "Select",
};

function handleSelfOrderDetails() {
    // Validation flags
    let hasError = false;

    // Helper function to set errors
    const setError = (message, errorSetter) => {
        setErrorCheck("All fields required");
        errorSetter(message);
        hasError = true;
    };

    // Validate order delivery type
    if (orderDeliveryType === ORDER_TYPES.SELECT) {
        setError("Please select order delivery type", setOrderDeliveryTypeError);
    } else {
        // Validate specific delivery types
        if ([ORDER_TYPES.DIAGNOSTICS_REVERSE, ORDER_TYPES.UNLOCKING_FLIGHT].includes(orderDeliveryType)) {
            if (destLocation === ORDER_TYPES.SELECT) {
                setError("Please select destination name", setDestinationLocationError);
            }
        }

        if (orderDeliveryType === ORDER_TYPES.DIAGNOSTICS_REVERSE) {
            if (recievedOn === "") {
                setError("Please select received on", setRecievedOnError);
            } 
            // else if (recievedOn === "Shopify" && orderNumber === 0) {
            //     setError("Order number cannot be zero", setErrorCheck);
            // }
        }

        if ([ORDER_TYPES.POC, ORDER_TYPES.TEST].includes(orderDeliveryType)) {
            if (!destinationData?.name) {
                setError("Please enter destination name", setDestinationLocationError);
            }
            if (!sourceData?.name) {
                setError("Please enter source name", setSourceLocationError);
            }
        }
    }

    // Validate payload details
    if (!payloadDetails.type || payloadDetails.weight === 0 ) {
        setErrorCheck("Payload details required");
        if (!payloadDetails.type) setError("Please enter valid type", setPayloadTypeError);
        if (payloadDetails.weight === 0) setError("Please enter valid weight", setPayloadWeightError);
    }

    // Check weight limit
    if (checkWeightLimitFunction(payloadDetails.weight)) {
        setError("Weight exceeds limit of 2500", setPayloadWeightError);
    }
    if (deliveryType === "") {
        setError("Delivery type is required", setDeliveryTypeError);
    }
    // Final validation
    if (hasError || !orderDeliveryType) {
        console.log("sdbfjsdjhsdf");
        return false;
    }

    // Navigate based on receivedOn
    // if (recievedOn === "TecheagleOMS") {
    //     setSelfOrderDetailsPopup(false);
    //     navigate("/all-categories");
    //     return false;
    // }

    // Default action
    setSelfOrderDetailsPopup(false);
    return true;
}

    

    const handleDeliveryRadioChange = (value) => {
        setDeliveryType(value);
        if (value === "Urgent") {
            setSelectDateVisibility(false);
        
        } else {
            setSelectDateVisibility(true);

        }
        setDeliveryTypeError("")
        // console.log("deliverytype", deliveryType);
    };
    const allOrderDeliveryTypes = ["Diagnostics(Reverse)", "POC", "Unlocking Flight", "Test"];
    // console.log(payloadWeightError, 213)
    return (
        <div className='selfOrderModel'>
            <h3 className='selfOrderModel__heading'>Self Order Details</h3>
            <div className="selfOrderModel__totalItems__locationCont1 ">
                <div className="selfOrderModel__totalItems__locationCont1__label"> My Details</div>
                <div className="selfOrderModel__customerInput selfOrderModel__Item1_Input">
                    <div className="selfOrderModel__customerInput__label">Name</div>
                    <div className="selfOrderModel__customerInput__value">
                        <input type="text"
                            value={userData.userName}
                            placeholder="Enter name"
                            className="selfOrderModel__customerInput__value__input"
                            // onChange={(e) => {
                            //     // setCustomerData({ ...user, "name": e.target.value })
                            //     setErrorCheck("")
                            // }}
                            readOnly
                        /></div>
                </div>
                <div className="selfOrderModel__customerInput" >
                    <div className="selfOrderModel__customerInput__label">Email</div>
                    <div className="selfOrderModel__customerInput__value" >
                        <input
                            type="email"

                            value={userData.userEmail}
                            style={{ color: emailError === true ? "red" : "var(--primaryTextColor)" }}
                            placeholder="Enter Email"
                            className="selfOrderModel__customerInput__value__input"
                            onChange={(e) => {
                                setCustomerData({ ...customerData, "email": e.target.value })
                                setEmailError(false)
                                setErrorCheck("")
                            }}

                        /></div>
                </div>
                <div className="selfOrderModel__customerInput">
                    <div className="selfOrderModel__customerInput__label">Phone</div>
                    <div className="selfOrderModel__customerInput__value">

                        <input
                            ref={phoneNoElementRef}
                            type="text" // Change to text for better control
                            value={userData.phoneNumber} // Display only the number
                            style={{ borderColor: PhoneNoError ? "red" : "var(--primaryTextColor)" }}
                            placeholder="Enter Phone no"
                            className="selfOrderModel__customerInput__value__input2"
                            // onChange={(e) => {
                            //     const input = e.target.value;
                            //     setErrorCheck("")
                            //     // Allow only numeric values
                            //     const sanitizedInput = input.replace(/[^0-9]/g, "");

                            //     if (sanitizedInput.length <= 10) {
                            //         setPhoneNoError(false);
                            //         const formattedPhone = `+91${sanitizedInput}`;
                            //         setCustomerData({ ...customerData, "phoneNo": formattedPhone });
                            //     }
                            // }}
                            readOnly
                        />

                    </div>
                </div>
                {/* <div className="selfOrderModel__customerInput" >
                    <div className="selfOrderModel__customerInput__label">Recieved On</div>
                    <div className="selfOrderModel__customerInput__value">
                        <select

                            value={recievedOn}
                            onChange={(e) => {
                                setRecievedOn(e.target.value)

                                setErrorCheck("")
                            }}
                            required
                            placeholder="Select Manual Order Type"
                            className="selfOrderModel__customerInput__value__input2 selfOrderModel__customerInput__value__input1"
                        >
                           
                        </select>
                    </div>
                </div> */}

            </div>
            <div className="selfOrderModel__Item2">
                <div className="selfOrderModel__inputFiled" style={{ border: (orderDeliveryTypeError && orderDeliveryType === "Select") ? "2px solid red" : "2px solid var(--primaryTextColor)" }}>
                    <div className="selfOrderModel__inputFiled__label">Order Delivery Type <span style={{ color: "red" }}>*</span></div>
                    <div className="selfOrderModel__inputFiled__value">
                        <select
                            value={orderDeliveryType}
                            onChange={(e) => {
                                if (e.target.value === "Select") {
                                    setErrorCheck("All fields required")
                                    setOrderDeliveryTypeError(true);
                                } else {
                                    setErrorCheck("")
                                    setOrderDeliveryTypeError(false);
                                }
                                setOrderDeliveryType(e.target.value)

                            }}
                            //defaultValue={destLocation}
                            //placeholder="Enter Confirm Password"
                            className="selfOrderModel__inputFiled__value__input"
                        >
                            <option value={"Select"}>
                                Select
                            </option>
                            {allOrderDeliveryTypes.map((option) => {
                                return <option key={option} value={option}>
                                    {option}
                                </option>
                            })}</select>
                    </div>
                    <div className='selfOrderModel__inputFiled__error'>{orderDeliveryTypeError}</div>
                </div>
                {orderDeliveryType === "Diagnostics(Reverse)" &&
                    // <div className="selfOrderModel__inputFiled">
                    //                 <div className="selfOrderModel__inputFiled__label">Recieved On<span style={{ color: "red" }}>*</span></div>
                    //                 <div className="selfOrderModel__inputFiled__value">
                    //                     <input
                    //                         value={recievedOn}
                    //                         onChange={(e) => {
                    //                             setRecievedOn(e.target.value)
                    //                             setErrorCheck("")
                    //                         }}
                    //                         type='text'
                    //                         placeholder="Enter Recieved On"
                    //                         className="selfOrderModel__inputFiled__value__input"
                    //                     /></div>
                    //             </div> 
                    // :
                    <div className="selfOrderModel__inputFiled" style={{ border: (recievedOnError !== "") ? "2px solid red" : "2px solid var(--primaryTextColor)" }}>
                        <div className="selfOrderModel__inputFiled__label">Recieved On <span style={{ color: "red" }}>*</span></div>
                        <div className="selfOrderModel__inputFiled__value">
                            <select
                                value={recievedOn}
                                onChange={(e) => {
                                    if (e.target.value === "Select") {
                                        setErrorCheck("All fields required")
                                        setRecievedOnError("Please Select Recieved On");
                                    } else {
                                        setErrorCheck("")
                                        setRecievedOnError("");
                                    }
                                    setRecievedOn(e.target.value)

                                    // setErrorCheck("")?
                                }}
                                required

                                className="selfOrderModel__inputFiled__value__input"
                            >
                                <option value={"Select"}>
                                    Select
                                </option>
                                {allrecievedOn.map((option) => <option key={option} value={option}> {option}</option>)}
                            </select>
                        </div>
                        <div className='selfOrderModel__inputFiled__error'>{recievedOnError}</div>
                    </div>}
            </div>
            <div className="selfOrderModel__Item5">

                {(orderDeliveryType !== "POC" && orderDeliveryType !== "Test") ?
                    orderDeliveryType !== "Diagnostics(Reverse)" ?
                        <>
                            <div className="selfOrderModel__inputFiled">
                                <div className="selfOrderModel__inputFiled__label">{orderDeliveryType !== "Diagnostics(Reverse)" ? "Source Location" : "Destination Location"}<span style={{ color: "red" }}>*</span></div>
                                <div className="selfOrderModel__inputFiled__value">
                                    <input
                                        readOnly
                                        value={hubLocation.location_name}
                                        //placeholder="Enter Confirm Password"
                                        className="selfOrderModel__inputFiled__value__input"
                                    /></div>
                            </div>

                            <div className="selfOrderModel__inputFiled" style={{ border: (destinationLocationError !== "" && destLocation === "Select") ? "2px solid red" : "2px solid var(--primaryTextColor)" }}>
                                <div className="selfOrderModel__inputFiled__label">{orderDeliveryType !== "Diagnostics(Reverse)" ? "Destination Location" : "Source Location"}<span style={{ color: "red" }}>*</span></div>
                                <div className="selfOrderModel__inputFiled__value">
                                    <select
                                        value={destLocation}
                                        onChange={(e) => {
                                            if (e.target.value === "Select") {
                                                setErrorCheck("All fields required")
                                                setDestinationLocationError("Please Select Destination Location");
                                            } else {
                                                setErrorCheck("")
                                                setDestinationLocationError("");
                                            }
                                            setDestLocation(e.target.value)

                                            console.log(e.target.value.split(",")[0]);
                                        }}
                                        className="selfOrderModel__inputFiled__value__input"
                                    >
                                        <option value={"Select"}>
                                            Select
                                        </option>
                                        {allLocations.map((option) => {
                                            //console.log("option", option._id, option.location_name)
                                            if (option.tag_name !== "Office" && option.tag_name !== "Meghalaya")
                                                return <option key={option._id} value={[option._id, option.location_name]}>
                                                    {option.location_name}
                                                </option>
                                            else {
                                                return
                                            }
                                        })}</select>
                                </div>
                                <div className='selfOrderModel__inputFiled__error'>{destinationLocationError}</div>
                            </div></> :
                        <>
                            <div className="selfOrderModel__inputFiled" style={{ border: (destinationLocationError !== "" && destLocation === "Select") ? "2px solid red" : "2px solid var(--primaryTextColor)" }}>
                                <div className="selfOrderModel__inputFiled__label">{orderDeliveryType !== "Diagnostics(Reverse)" ? "Destination Location" : "Source Location"}<span style={{ color: "red" }}>*</span></div>
                                <div className="selfOrderModel__inputFiled__value">
                                    <select
                                        value={destLocation}
                                        onChange={(e) => {
                                            if (e.target.value === "Select") {
                                                setErrorCheck("All fields required")
                                                setDestinationLocationError("Please Select Destination Location");
                                            } else {
                                                setErrorCheck("")
                                                setDestinationLocationError("");
                                            }
                                            setDestLocation(e.target.value)

                                            console.log(e.target.value.split(",")[0]);
                                        }}
                                        className="selfOrderModel__inputFiled__value__input"
                                    >
                                        <option value={"Select"}>
                                            Select
                                        </option>
                                        {allLocations.map((option) => {
                                            //console.log("option", option._id, option.location_name)
                                            if (option.tag_name !== "Office" && option.tag_name !== "Meghalaya")
                                                return <option key={option._id} value={[option._id, option.location_name]}>
                                                    {option.location_name}
                                                </option>
                                            else {
                                                return
                                            }
                                        })}</select>
                                </div>
                                <div className='selfOrderModel__inputFiled__error'>{destinationLocationError}</div>
                            </div>
                            <div className="selfOrderModel__inputFiled">
                                <div className="selfOrderModel__inputFiled__label">{orderDeliveryType !== "Diagnostics(Reverse)" ? "Source Location" : "Destination Location"}<span style={{ color: "red" }}>*</span></div>
                                <div className="selfOrderModel__inputFiled__value">
                                    <input
                                        readOnly
                                        value={hubLocation.location_name}
                                        //placeholder="Enter Confirm Password"
                                        className="selfOrderModel__inputFiled__value__input"
                                    /></div>
                            </div></> :
                    <>
                        <div className="selfOrderModel__inputFiled" style={{ border: (sourceLocationError !== "" && sourceData.name.length < 3) ? "2px solid red" : "2px solid var(--primaryTextColor)" }}>
                            <div className="selfOrderModel__inputFiled__label">Source Location Name<span style={{ color: "red" }}>*</span></div>
                            <div className="selfOrderModel__inputFiled__value">
                                <input
                                    value={sourceData.name}
                                    onChange={(e) => {
                                        if (e.target.value.length < 3) {
                                            setErrorCheck("All fields required")
                                            setSourceLocationError("Length must be > 3");
                                        } else {
                                            setErrorCheck("")
                                            setSourceLocationError("");
                                        }
                                        setSourceData({ ...sourceData, name: e.target.value })
                                    }}
                                    type="text"
                                    placeholder="Enter Source Location Name"
                                    className="selfOrderModel__inputFiled__value__input"
                                /></div>
                            <div className='selfOrderModel__inputFiled__error'>{sourceLocationError}</div>
                        </div>
                        <div className="selfOrderModel__inputFiled" style={{ border: (destinationLocationError !== "" && destinationData.name.length < 3) ? "2px solid red" : "2px solid var(--primaryTextColor)" }}>
                            <div className="selfOrderModel__inputFiled__label" >Destination Location Name <span style={{ color: "red" }}>*</span></div>
                            <div className="selfOrderModel__inputFiled__value">
                                <input
                                    value={destinationData.name}
                                    onChange={(e) => {
                                        if (e.target.value.length < 3) {
                                            setErrorCheck("All fields required")
                                            setDestinationLocationError("Length must be > 3");
                                        } else {
                                            setErrorCheck("")
                                            setDestinationLocationError("");
                                        }
                                        setDestinationData({ ...destinationData, name: e.target.value })
                                    }}
                                    type="text"
                                    placeholder="Enter Destination Location Name"
                                    className="selfOrderModel__inputFiled__value__input"
                                /></div>
                            <div className='selfOrderModel__inputFiled__error'>{destinationLocationError}</div>
                        </div>

                        <div className="selfOrderModel__inputFiled">
                            <div className="selfOrderModel__inputFiled__label">Source Longitude</div>
                            <div className="selfOrderModel__inputFiled__value">
                                <input
                                    value={sourceData.long}
                                    type='number'
                                    onChange={(e) => setSourceData({ ...sourceData, long: e.target.value })}
                                    placeholder="Enter Source Longitude"
                                    className="selfOrderModel__inputFiled__value__input"
                                /></div>
                        </div>
                        <div className="selfOrderModel__inputFiled">
                            <div className="selfOrderModel__inputFiled__label">Destination Longitude</div>
                            <div className="selfOrderModel__inputFiled__value">
                                <input
                                    type='number'
                                    value={destinationData.long}
                                    onChange={(e) => setDestinationData({ ...destinationData, long: e.target.value })}
                                    placeholder="Enter Destination Longitude"
                                    className="selfOrderModel__inputFiled__value__input"
                                /></div>
                        </div>
                        <div className="selfOrderModel__inputFiled">
                            <div className="selfOrderModel__inputFiled__label">Source Latitude</div>
                            <div className="selfOrderModel__inputFiled__value">
                                <input
                                    type='number'
                                    value={sourceData.lat}
                                    onChange={(e) => setSourceData({ ...sourceData, lat: e.target.value })}
                                    placeholder="Enter Source Latitude"
                                    className="selfOrderModel__inputFiled__value__input"
                                /></div>
                        </div>
                        <div className="selfOrderModel__inputFiled">
                            <div className="selfOrderModel__inputFiled__label">Destination Latitude </div>
                            <div className="selfOrderModel__inputFiled__value">
                                <input
                                    type='number'
                                    value={destinationData.lat}
                                    onChange={(e) => setDestinationData({ ...destinationData, lat: e.target.value })}
                                    placeholder="Enter Destination Latitude "
                                    className="selfOrderModel__inputFiled__value__input"
                                /></div>
                        </div>
                    </>}

                {/* </div> */}

                {/* <div className='selfOrderModel__Item4'> */}
                {/* {recievedOn !== "TecheagleOMS" && <> */}
                    <div className="selfOrderModel__inputFiled" style={{ border: (payloadTypeError !== "" && payloadDetails.type.length < 3) ? "2px solid red" : "2px solid var(--primaryTextColor)" }}>
                        <div className="selfOrderModel__inputFiled__label">Payload Type<span style={{ color: "red" }}>*</span></div>
                        <div className="selfOrderModel__inputFiled__value">
                            <input
                                value={payloadDetails.type}
                                onChange={(e) => {
                                    if (e.target.value.length < 3) {
                                        setErrorCheck("All fields required")
                                        setPayloadTypeError("Length must be > 3")
                                    } else {
                                        setErrorCheck("")
                                        setPayloadTypeError("")
                                    }
                                    setPayloadDetails({ ...payloadDetails, type: e.target.value })
                                }}
                                type='text'
                                placeholder="Enter payload type"
                                className="selfOrderModel__inputFiled__value__input"
                            /></div>
                        <div className='selfOrderModel__inputFiled__error'>{payloadTypeError}</div>
                    </div>

                    <div className="selfOrderModel__inputFiled">
                        <div className="selfOrderModel__inputFiled__label">Payload Description</div>
                        <div className="selfOrderModel__inputFiled__value">
                            <input
                                value={payloadDetails.description}
                                onChange={(e) => setPayloadDetails({ ...payloadDetails, description: e.target.value })}
                                type='text'
                                placeholder="Enter payload description"
                                className="selfOrderModel__inputFiled__value__input"
                            /></div>
                    </div>
                    <div className="selfOrderModel__inputFiled" style={{ border: (checkWeightLimitFunction(payloadDetails.weight) || payloadWeightError !== "") ? "2px solid red" : "2px solid var(--primaryTextColor)" }}>
                        <div className="selfOrderModel__inputFiled__label">Payload Weight<span style={{ color: "red" }}>*</span></div>
                        <div className="selfOrderModel__inputFiled__value">
                            <input
                                value={payloadDetails.weight}
                                onChange={(e) => {
                                    setPayloadDetails({ ...payloadDetails, weight: e.target.value });
                                    if (checkWeightLimitFunction(e.target.value)) {
                                        setErrorCheck("weight limit 2500");
                                        setPayloadWeightError("weight limit 2500")
                                    } else {
                                        setErrorCheck("");
                                        setPayloadWeightError("")
                                    }
                                }}
                                type="number"
                                placeholder="Enter payload"
                                className="selfOrderModel__inputFiled__value__input"
                            />g</div>
                        <div className='selfOrderModel__inputFiled__error'>{payloadWeightError}</div>
                    </div>
                {/* </>} */}
                {/* {recievedOn === "Shopify" && <div className="selfOrderModel__inputFiled">
                    <div className="selfOrderModel__inputFiled__label">Order Number<span style={{ color: "red" }}>*</span></div>
                    <div className="selfOrderModel__inputFiled__value">
                        <input
                            value={orderNumber}
                            onChange={(e) => setOrderNumber(e.target.value)}
                            type='number'
                            placeholder="Enter Order Number"
                            className="selfOrderModel__inputFiled__value__input"
                        /></div>
                </div>} */}
            </div>
            <div className="selfOrderModel__totalItems__locationCont2">
                <Form.Group
                    controlId="delivery-type"
                    className="selfOrderModel__totalItems__locationCont2__delivery-type-group"
                >
                    <Form.ControlLabel className="selfOrderModel__totalItems__locationCont2__delivery-type-label">
                        Delivery Type<span style={{ color: "red" }}>*</span>
                    </Form.ControlLabel>
                    <RadioGroup
                        inline
                        name="controlled-radio-buttons-group"
                        value={deliveryType}
                        onChange={handleDeliveryRadioChange}
                        className="selfOrderModel__totalItems__locationCont2__delivery-type-radio-group"
                    >
                        <Radio value="Urgent" className="selfOrderModel__totalItems__locationCont2__delivery-type-radio">
                            Urgent
                        </Radio>
                        <Radio value="Scheduled" className="selfOrderModel__totalItems__locationCont2__delivery-type-radio">
                            Scheduled
                            <DatePicker
                                format="MM/dd/yyyy hh:mm aa"
                                value={deliveryDate}
                                oneTap={true}
                                onChange={(date) => {
                                    setSelectDateVisibility(false)
                                }}
                                className='selfOrderModel__totalItems__locationCont2__delivery-type-radio__datePicker'
                                open={selectDateVisibility}
                                placement="topStart"
                            />
                        </Radio>

                    </RadioGroup>
                </Form.Group>
                <div className='selfOrderModel__totalItems__locationCont2__error'>{deliveryTypeError}</div>
            </div>

            <div className='selfOrderModel__applyItems'>

                <div > <button className="selfOrderModel__applyItems__placeOrder"
                    onClick={recievedOn === "TecheagleOMS" ? handleSelfOrderDetails : handlePlaceOrderClick}>
                    Place Order</button>
                </div>

            </div>
            <div > <button className="selfOrderModel__close"
                onClick={handleSelfOrderModelClose}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>

            </button>
            </div>
            {/* <p style={{ visibility: errorCheck !== "" ? "visible" : "hidden" }} className='selfOrderModel__error'>{errorCheck ? errorCheck : "All fields required"}</p> */}
        </div>
    )
}

export default SelfOrderModel;