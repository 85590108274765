import axios from "axios";
import { React, useEffect, useRef, useState } from "react";
import "./OrderCreateSelect.scss"
import DashboardCard from "../DashboardCard/DashboardCard";
import OrderModel from "../OrderModel/OrderModel";
import SelfOrderModel from "../SelfOrderModel/SelfOrderModel";

const OrderCreateSelect = ({ userCookie, selectedTab, setSelectedTab, setSelectedSub, userHubLocation, myLocation, allLocations, handleNotifyAlert, SetLoadingVisibility, customerDetailsPopup, setCustomerDetailsPopup }) => {

    // const [customerDetailsPopup, setCustomerDetailsPopup] = useState(false);
    const [selfOrderDetailsPopup, setSelfOrderDetailsPopup] = useState(false);
    const availableModules = [
        { label: "Self order", path: "create-order/#", tabName: "Create Order" },
        { label: "Order for customer", path: "create-order/#", tabName: "Create Order" },
    ]
    // console.log("UserCookie", userCookie)

    useEffect(() => {
        console.log("customerDetailsPopup", customerDetailsPopup)
    }, [])
    return (
        <div className="createOrderSelect-container">
            <div className="createOrderSelect-container__Header">
                <div className="createOrderSelect-container__Header__title">Create Order</div>
            </div>
            <div className="createOrderSelect-container__Header2">
                <div className="createOrderSelect-container__Header2__title">For whom are you ordering ?</div>
            </div>
            <div className="createOrderSelect-container__cardComponent">
            
                {availableModules.map((modules) => {
                    return <DashboardCard
                        modulelabel={modules.label}
                        navigateTo={modules.path}
                        setSelected={setSelectedTab}
                        subContent={setSelectedSub}
                        tabName={modules.tabName}
                        setCustomerDetailsPopup={setCustomerDetailsPopup}
                        setSelfOrderDetailsPopup={setSelfOrderDetailsPopup} />
                })}
            </div>
            {customerDetailsPopup && <div className="createOrderSelect-container__orderModel_container">
                <OrderModel
                    userHubLocation={userHubLocation}
                    myLocation={myLocation}
                    allLocations={allLocations}
                    setCustomerDetailsPopup={setCustomerDetailsPopup}
                    deliveryType
                    handleNotifyAlert={handleNotifyAlert}
                    SetLoadingVisibility={SetLoadingVisibility}
                    userCookie={userCookie}
                />
            </div> } 
            {selfOrderDetailsPopup && <div className="createOrderSelect-container__orderModel_container">
                <SelfOrderModel
                    userHubLocation={userHubLocation}
                    myLocation={myLocation}
                    allLocations={allLocations}
                    setSelfOrderDetailsPopup={setSelfOrderDetailsPopup}
                    deliveryType
                    handleNotifyAlert={handleNotifyAlert}
                    SetLoadingVisibility={SetLoadingVisibility}
                    userCookie={userCookie}
                />
            </div>}
            
        </div>
    )
}


export default OrderCreateSelect;